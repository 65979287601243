// src/components/archive/EmailView/utils.tsx

import DOMPurify from "dompurify"

/**
 * Sanitizes the raw email HTML content by extracting the <body> content
 * and cleaning it using DOMPurify to prevent XSS attacks.
 */
export function sanatizeEmailHTML(html: string): {
  sanitizedContent: string | null
  prunedContent: string | null
} {
  if (!html) return {sanitizedContent: null, prunedContent: null}
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, "text/html")
  const bodyContent = doc.body ? doc.body.innerHTML : ""

  const sanitizedContent = DOMPurify.sanitize(bodyContent, {
    ALLOWED_TAGS: [
      "html",
      "body",
      "div",
      "span",
      "p",
      "h1",
      "h2",
      "h3",
      "h4",
      "h5",
      "h6",
      "font",
      "hr",
    ],
    ALLOWED_ATTR: [
      "class",
      "style",
      "id",
      "dir",
      "tabindex",
      "color",
      "face",
      "size",
    ],
  })

  const prunedContent = DOMPurify.sanitize(bodyContent, {
    ALLOWED_TAGS: ["div"],
    ALLOWED_ATTR: [],
  })

  return {sanitizedContent, prunedContent}
}
