/* eslint-disable */
import {TypedDocumentNode as DocumentNode} from "@graphql-typed-document-node/core"
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends {[key: string]: unknown}> = {[K in keyof T]: T[K]}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<T extends {[key: string]: unknown}, K extends keyof T> = {
  [_ in K]?: never
}
export type Incremental<T> =
  | T
  | {[P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: {input: string; output: string}
  String: {input: string; output: string}
  Boolean: {input: boolean; output: boolean}
  Int: {input: number; output: number}
  Float: {input: number; output: number}
  /** A high precision floating point value represented as a string */
  BigFloat: {input: string; output: string}
  /** An arbitrary size integer represented as a string */
  BigInt: {input: string; output: string}
  /** An opaque string using for tracking a position in results during pagination */
  Cursor: {input: any; output: any}
  /** A date without time information */
  Date: {input: string; output: string}
  /** A date and time */
  Datetime: {input: string; output: string}
  /** A Javascript Object Notation value serialized as a string */
  JSON: {input: string; output: string}
  /** Any type not handled by the type system */
  Opaque: {input: any; output: any}
  /** A time without date information */
  Time: {input: string; output: string}
  /** A universally unique identifier */
  UUID: {input: string; output: string}
}

/** Boolean expression comparing fields on type "BigFloat" */
export type BigFloatFilter = {
  eq?: InputMaybe<Scalars["BigFloat"]["input"]>
  gt?: InputMaybe<Scalars["BigFloat"]["input"]>
  gte?: InputMaybe<Scalars["BigFloat"]["input"]>
  in?: InputMaybe<Array<Scalars["BigFloat"]["input"]>>
  is?: InputMaybe<FilterIs>
  lt?: InputMaybe<Scalars["BigFloat"]["input"]>
  lte?: InputMaybe<Scalars["BigFloat"]["input"]>
  neq?: InputMaybe<Scalars["BigFloat"]["input"]>
}

/** Boolean expression comparing fields on type "BigFloatList" */
export type BigFloatListFilter = {
  containedBy?: InputMaybe<Array<Scalars["BigFloat"]["input"]>>
  contains?: InputMaybe<Array<Scalars["BigFloat"]["input"]>>
  eq?: InputMaybe<Array<Scalars["BigFloat"]["input"]>>
  is?: InputMaybe<FilterIs>
  overlaps?: InputMaybe<Array<Scalars["BigFloat"]["input"]>>
}

/** Boolean expression comparing fields on type "BigInt" */
export type BigIntFilter = {
  eq?: InputMaybe<Scalars["BigInt"]["input"]>
  gt?: InputMaybe<Scalars["BigInt"]["input"]>
  gte?: InputMaybe<Scalars["BigInt"]["input"]>
  in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  is?: InputMaybe<FilterIs>
  lt?: InputMaybe<Scalars["BigInt"]["input"]>
  lte?: InputMaybe<Scalars["BigInt"]["input"]>
  neq?: InputMaybe<Scalars["BigInt"]["input"]>
}

/** Boolean expression comparing fields on type "BigIntList" */
export type BigIntListFilter = {
  containedBy?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  contains?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  eq?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
  is?: InputMaybe<FilterIs>
  overlaps?: InputMaybe<Array<Scalars["BigInt"]["input"]>>
}

/** Boolean expression comparing fields on type "Boolean" */
export type BooleanFilter = {
  eq?: InputMaybe<Scalars["Boolean"]["input"]>
  is?: InputMaybe<FilterIs>
}

/** Boolean expression comparing fields on type "BooleanList" */
export type BooleanListFilter = {
  containedBy?: InputMaybe<Array<Scalars["Boolean"]["input"]>>
  contains?: InputMaybe<Array<Scalars["Boolean"]["input"]>>
  eq?: InputMaybe<Array<Scalars["Boolean"]["input"]>>
  is?: InputMaybe<FilterIs>
  overlaps?: InputMaybe<Array<Scalars["Boolean"]["input"]>>
}

/** Boolean expression comparing fields on type "Date" */
export type DateFilter = {
  eq?: InputMaybe<Scalars["Date"]["input"]>
  gt?: InputMaybe<Scalars["Date"]["input"]>
  gte?: InputMaybe<Scalars["Date"]["input"]>
  in?: InputMaybe<Array<Scalars["Date"]["input"]>>
  is?: InputMaybe<FilterIs>
  lt?: InputMaybe<Scalars["Date"]["input"]>
  lte?: InputMaybe<Scalars["Date"]["input"]>
  neq?: InputMaybe<Scalars["Date"]["input"]>
}

/** Boolean expression comparing fields on type "DateList" */
export type DateListFilter = {
  containedBy?: InputMaybe<Array<Scalars["Date"]["input"]>>
  contains?: InputMaybe<Array<Scalars["Date"]["input"]>>
  eq?: InputMaybe<Array<Scalars["Date"]["input"]>>
  is?: InputMaybe<FilterIs>
  overlaps?: InputMaybe<Array<Scalars["Date"]["input"]>>
}

/** Boolean expression comparing fields on type "Datetime" */
export type DatetimeFilter = {
  eq?: InputMaybe<Scalars["Datetime"]["input"]>
  gt?: InputMaybe<Scalars["Datetime"]["input"]>
  gte?: InputMaybe<Scalars["Datetime"]["input"]>
  in?: InputMaybe<Array<Scalars["Datetime"]["input"]>>
  is?: InputMaybe<FilterIs>
  lt?: InputMaybe<Scalars["Datetime"]["input"]>
  lte?: InputMaybe<Scalars["Datetime"]["input"]>
  neq?: InputMaybe<Scalars["Datetime"]["input"]>
}

/** Boolean expression comparing fields on type "DatetimeList" */
export type DatetimeListFilter = {
  containedBy?: InputMaybe<Array<Scalars["Datetime"]["input"]>>
  contains?: InputMaybe<Array<Scalars["Datetime"]["input"]>>
  eq?: InputMaybe<Array<Scalars["Datetime"]["input"]>>
  is?: InputMaybe<FilterIs>
  overlaps?: InputMaybe<Array<Scalars["Datetime"]["input"]>>
}

export enum FilterIs {
  NotNull = "NOT_NULL",
  Null = "NULL",
}

/** Boolean expression comparing fields on type "Float" */
export type FloatFilter = {
  eq?: InputMaybe<Scalars["Float"]["input"]>
  gt?: InputMaybe<Scalars["Float"]["input"]>
  gte?: InputMaybe<Scalars["Float"]["input"]>
  in?: InputMaybe<Array<Scalars["Float"]["input"]>>
  is?: InputMaybe<FilterIs>
  lt?: InputMaybe<Scalars["Float"]["input"]>
  lte?: InputMaybe<Scalars["Float"]["input"]>
  neq?: InputMaybe<Scalars["Float"]["input"]>
}

/** Boolean expression comparing fields on type "FloatList" */
export type FloatListFilter = {
  containedBy?: InputMaybe<Array<Scalars["Float"]["input"]>>
  contains?: InputMaybe<Array<Scalars["Float"]["input"]>>
  eq?: InputMaybe<Array<Scalars["Float"]["input"]>>
  is?: InputMaybe<FilterIs>
  overlaps?: InputMaybe<Array<Scalars["Float"]["input"]>>
}

/** Boolean expression comparing fields on type "ID" */
export type IdFilter = {
  eq?: InputMaybe<Scalars["ID"]["input"]>
}

/** Boolean expression comparing fields on type "Int" */
export type IntFilter = {
  eq?: InputMaybe<Scalars["Int"]["input"]>
  gt?: InputMaybe<Scalars["Int"]["input"]>
  gte?: InputMaybe<Scalars["Int"]["input"]>
  in?: InputMaybe<Array<Scalars["Int"]["input"]>>
  is?: InputMaybe<FilterIs>
  lt?: InputMaybe<Scalars["Int"]["input"]>
  lte?: InputMaybe<Scalars["Int"]["input"]>
  neq?: InputMaybe<Scalars["Int"]["input"]>
}

/** Boolean expression comparing fields on type "IntList" */
export type IntListFilter = {
  containedBy?: InputMaybe<Array<Scalars["Int"]["input"]>>
  contains?: InputMaybe<Array<Scalars["Int"]["input"]>>
  eq?: InputMaybe<Array<Scalars["Int"]["input"]>>
  is?: InputMaybe<FilterIs>
  overlaps?: InputMaybe<Array<Scalars["Int"]["input"]>>
}

/** Boolean expression comparing fields on type "Opaque" */
export type OpaqueFilter = {
  eq?: InputMaybe<Scalars["Opaque"]["input"]>
  is?: InputMaybe<FilterIs>
}

/** Defines a per-field sorting order */
export enum OrderByDirection {
  /** Ascending order, nulls first */
  AscNullsFirst = "AscNullsFirst",
  /** Ascending order, nulls last */
  AscNullsLast = "AscNullsLast",
  /** Descending order, nulls first */
  DescNullsFirst = "DescNullsFirst",
  /** Descending order, nulls last */
  DescNullsLast = "DescNullsLast",
}

/** Boolean expression comparing fields on type "String" */
export type StringFilter = {
  eq?: InputMaybe<Scalars["String"]["input"]>
  gt?: InputMaybe<Scalars["String"]["input"]>
  gte?: InputMaybe<Scalars["String"]["input"]>
  ilike?: InputMaybe<Scalars["String"]["input"]>
  in?: InputMaybe<Array<Scalars["String"]["input"]>>
  iregex?: InputMaybe<Scalars["String"]["input"]>
  is?: InputMaybe<FilterIs>
  like?: InputMaybe<Scalars["String"]["input"]>
  lt?: InputMaybe<Scalars["String"]["input"]>
  lte?: InputMaybe<Scalars["String"]["input"]>
  neq?: InputMaybe<Scalars["String"]["input"]>
  regex?: InputMaybe<Scalars["String"]["input"]>
  startsWith?: InputMaybe<Scalars["String"]["input"]>
}

/** Boolean expression comparing fields on type "StringList" */
export type StringListFilter = {
  containedBy?: InputMaybe<Array<Scalars["String"]["input"]>>
  contains?: InputMaybe<Array<Scalars["String"]["input"]>>
  eq?: InputMaybe<Array<Scalars["String"]["input"]>>
  is?: InputMaybe<FilterIs>
  overlaps?: InputMaybe<Array<Scalars["String"]["input"]>>
}

/** Boolean expression comparing fields on type "Time" */
export type TimeFilter = {
  eq?: InputMaybe<Scalars["Time"]["input"]>
  gt?: InputMaybe<Scalars["Time"]["input"]>
  gte?: InputMaybe<Scalars["Time"]["input"]>
  in?: InputMaybe<Array<Scalars["Time"]["input"]>>
  is?: InputMaybe<FilterIs>
  lt?: InputMaybe<Scalars["Time"]["input"]>
  lte?: InputMaybe<Scalars["Time"]["input"]>
  neq?: InputMaybe<Scalars["Time"]["input"]>
}

/** Boolean expression comparing fields on type "TimeList" */
export type TimeListFilter = {
  containedBy?: InputMaybe<Array<Scalars["Time"]["input"]>>
  contains?: InputMaybe<Array<Scalars["Time"]["input"]>>
  eq?: InputMaybe<Array<Scalars["Time"]["input"]>>
  is?: InputMaybe<FilterIs>
  overlaps?: InputMaybe<Array<Scalars["Time"]["input"]>>
}

/** Boolean expression comparing fields on type "UUID" */
export type UuidFilter = {
  eq?: InputMaybe<Scalars["UUID"]["input"]>
  in?: InputMaybe<Array<Scalars["UUID"]["input"]>>
  is?: InputMaybe<FilterIs>
  neq?: InputMaybe<Scalars["UUID"]["input"]>
}

/** Boolean expression comparing fields on type "UUIDList" */
export type UuidListFilter = {
  containedBy?: InputMaybe<Array<Scalars["UUID"]["input"]>>
  contains?: InputMaybe<Array<Scalars["UUID"]["input"]>>
  eq?: InputMaybe<Array<Scalars["UUID"]["input"]>>
  is?: InputMaybe<FilterIs>
  overlaps?: InputMaybe<Array<Scalars["UUID"]["input"]>>
}

export type AttachmentsFilter = {
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<AttachmentsFilter>>
  attachment_id?: InputMaybe<UuidFilter>
  message_id?: InputMaybe<UuidFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<AttachmentsFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<AttachmentsFilter>>
  org_id?: InputMaybe<UuidFilter>
  s3_uri?: InputMaybe<StringFilter>
}

export type AttachmentsInsertInput = {
  attachment_id?: InputMaybe<Scalars["UUID"]["input"]>
  message_id?: InputMaybe<Scalars["UUID"]["input"]>
  org_id?: InputMaybe<Scalars["UUID"]["input"]>
  s3_uri?: InputMaybe<Scalars["String"]["input"]>
}

export type AttachmentsOrderBy = {
  attachment_id?: InputMaybe<OrderByDirection>
  message_id?: InputMaybe<OrderByDirection>
  org_id?: InputMaybe<OrderByDirection>
  s3_uri?: InputMaybe<OrderByDirection>
}

export type AttachmentsUpdateInput = {
  attachment_id?: InputMaybe<Scalars["UUID"]["input"]>
  message_id?: InputMaybe<Scalars["UUID"]["input"]>
  org_id?: InputMaybe<Scalars["UUID"]["input"]>
  s3_uri?: InputMaybe<Scalars["String"]["input"]>
}

export type Extraction_ContactsFilter = {
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<Extraction_ContactsFilter>>
  attachment_sender_id?: InputMaybe<UuidFilter>
  contact_id?: InputMaybe<UuidFilter>
  contact_name?: InputMaybe<StringFilter>
  created_at?: InputMaybe<DatetimeFilter>
  group_id?: InputMaybe<UuidFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<Extraction_ContactsFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<Extraction_ContactsFilter>>
  org_id?: InputMaybe<UuidFilter>
  profile_id?: InputMaybe<UuidFilter>
}

export type Extraction_ContactsInsertInput = {
  attachment_sender_id?: InputMaybe<Scalars["UUID"]["input"]>
  contact_id?: InputMaybe<Scalars["UUID"]["input"]>
  contact_name?: InputMaybe<Scalars["String"]["input"]>
  created_at?: InputMaybe<Scalars["Datetime"]["input"]>
  group_id?: InputMaybe<Scalars["UUID"]["input"]>
  org_id?: InputMaybe<Scalars["UUID"]["input"]>
  profile_id?: InputMaybe<Scalars["UUID"]["input"]>
}

export type Extraction_ContactsOrderBy = {
  attachment_sender_id?: InputMaybe<OrderByDirection>
  contact_id?: InputMaybe<OrderByDirection>
  contact_name?: InputMaybe<OrderByDirection>
  created_at?: InputMaybe<OrderByDirection>
  group_id?: InputMaybe<OrderByDirection>
  org_id?: InputMaybe<OrderByDirection>
  profile_id?: InputMaybe<OrderByDirection>
}

export type Extraction_ContactsUpdateInput = {
  attachment_sender_id?: InputMaybe<Scalars["UUID"]["input"]>
  contact_id?: InputMaybe<Scalars["UUID"]["input"]>
  contact_name?: InputMaybe<Scalars["String"]["input"]>
  created_at?: InputMaybe<Scalars["Datetime"]["input"]>
  group_id?: InputMaybe<Scalars["UUID"]["input"]>
  org_id?: InputMaybe<Scalars["UUID"]["input"]>
  profile_id?: InputMaybe<Scalars["UUID"]["input"]>
}

export type Extraction_MessageFilter = {
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<Extraction_MessageFilter>>
  contact_id?: InputMaybe<UuidFilter>
  contact_name?: InputMaybe<StringFilter>
  content?: InputMaybe<StringFilter>
  created_at?: InputMaybe<DatetimeFilter>
  extraction_id?: InputMaybe<UuidFilter>
  extraction_message_id?: InputMaybe<UuidFilter>
  group_id?: InputMaybe<UuidFilter>
  index?: InputMaybe<IntFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<Extraction_MessageFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<Extraction_MessageFilter>>
  org_id?: InputMaybe<UuidFilter>
  timestamp?: InputMaybe<StringFilter>
  version?: InputMaybe<IntFilter>
}

export type Extraction_MessageInsertInput = {
  contact_id?: InputMaybe<Scalars["UUID"]["input"]>
  contact_name?: InputMaybe<Scalars["String"]["input"]>
  content?: InputMaybe<Scalars["String"]["input"]>
  created_at?: InputMaybe<Scalars["Datetime"]["input"]>
  extraction_id?: InputMaybe<Scalars["UUID"]["input"]>
  extraction_message_id?: InputMaybe<Scalars["UUID"]["input"]>
  group_id?: InputMaybe<Scalars["UUID"]["input"]>
  index?: InputMaybe<Scalars["Int"]["input"]>
  org_id?: InputMaybe<Scalars["UUID"]["input"]>
  timestamp?: InputMaybe<Scalars["String"]["input"]>
  version?: InputMaybe<Scalars["Int"]["input"]>
}

export type Extraction_MessageOrderBy = {
  contact_id?: InputMaybe<OrderByDirection>
  contact_name?: InputMaybe<OrderByDirection>
  content?: InputMaybe<OrderByDirection>
  created_at?: InputMaybe<OrderByDirection>
  extraction_id?: InputMaybe<OrderByDirection>
  extraction_message_id?: InputMaybe<OrderByDirection>
  group_id?: InputMaybe<OrderByDirection>
  index?: InputMaybe<OrderByDirection>
  org_id?: InputMaybe<OrderByDirection>
  timestamp?: InputMaybe<OrderByDirection>
  version?: InputMaybe<OrderByDirection>
}

export type Extraction_MessageUpdateInput = {
  contact_id?: InputMaybe<Scalars["UUID"]["input"]>
  contact_name?: InputMaybe<Scalars["String"]["input"]>
  content?: InputMaybe<Scalars["String"]["input"]>
  created_at?: InputMaybe<Scalars["Datetime"]["input"]>
  extraction_id?: InputMaybe<Scalars["UUID"]["input"]>
  extraction_message_id?: InputMaybe<Scalars["UUID"]["input"]>
  group_id?: InputMaybe<Scalars["UUID"]["input"]>
  index?: InputMaybe<Scalars["Int"]["input"]>
  org_id?: InputMaybe<Scalars["UUID"]["input"]>
  timestamp?: InputMaybe<Scalars["String"]["input"]>
  version?: InputMaybe<Scalars["Int"]["input"]>
}

export type ExtractionsFilter = {
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<ExtractionsFilter>>
  attachment_id?: InputMaybe<UuidFilter>
  created_at?: InputMaybe<DatetimeFilter>
  extraction_id?: InputMaybe<UuidFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<ExtractionsFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<ExtractionsFilter>>
  org_id?: InputMaybe<UuidFilter>
}

export type ExtractionsInsertInput = {
  attachment_id?: InputMaybe<Scalars["UUID"]["input"]>
  created_at?: InputMaybe<Scalars["Datetime"]["input"]>
  extraction_id?: InputMaybe<Scalars["UUID"]["input"]>
  org_id?: InputMaybe<Scalars["UUID"]["input"]>
  raw_output?: InputMaybe<Scalars["JSON"]["input"]>
}

export type ExtractionsOrderBy = {
  attachment_id?: InputMaybe<OrderByDirection>
  created_at?: InputMaybe<OrderByDirection>
  extraction_id?: InputMaybe<OrderByDirection>
  org_id?: InputMaybe<OrderByDirection>
}

export type ExtractionsUpdateInput = {
  attachment_id?: InputMaybe<Scalars["UUID"]["input"]>
  created_at?: InputMaybe<Scalars["Datetime"]["input"]>
  extraction_id?: InputMaybe<Scalars["UUID"]["input"]>
  org_id?: InputMaybe<Scalars["UUID"]["input"]>
  raw_output?: InputMaybe<Scalars["JSON"]["input"]>
}

export type Group_ParticipantsFilter = {
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<Group_ParticipantsFilter>>
  group_id?: InputMaybe<UuidFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<Group_ParticipantsFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<Group_ParticipantsFilter>>
  profile_id?: InputMaybe<UuidFilter>
}

export type Group_ParticipantsInsertInput = {
  group_id?: InputMaybe<Scalars["UUID"]["input"]>
  profile_id?: InputMaybe<Scalars["UUID"]["input"]>
}

export type Group_ParticipantsOrderBy = {
  group_id?: InputMaybe<OrderByDirection>
  profile_id?: InputMaybe<OrderByDirection>
}

export type Group_ParticipantsUpdateInput = {
  group_id?: InputMaybe<Scalars["UUID"]["input"]>
  profile_id?: InputMaybe<Scalars["UUID"]["input"]>
}

export type InvitesFilter = {
  accepted?: InputMaybe<BooleanFilter>
  accepted_at?: InputMaybe<DatetimeFilter>
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<InvitesFilter>>
  auth_user_id?: InputMaybe<UuidFilter>
  created_at?: InputMaybe<DatetimeFilter>
  email?: InputMaybe<StringFilter>
  expired_at?: InputMaybe<DatetimeFilter>
  invite_id?: InputMaybe<UuidFilter>
  inviter_auth_user_id?: InputMaybe<UuidFilter>
  name?: InputMaybe<StringFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<InvitesFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<InvitesFilter>>
  org_id?: InputMaybe<UuidFilter>
  phone_number?: InputMaybe<StringFilter>
  role?: InputMaybe<StringFilter>
}

export type InvitesInsertInput = {
  accepted?: InputMaybe<Scalars["Boolean"]["input"]>
  accepted_at?: InputMaybe<Scalars["Datetime"]["input"]>
  auth_user_id?: InputMaybe<Scalars["UUID"]["input"]>
  created_at?: InputMaybe<Scalars["Datetime"]["input"]>
  email?: InputMaybe<Scalars["String"]["input"]>
  expired_at?: InputMaybe<Scalars["Datetime"]["input"]>
  invite_id?: InputMaybe<Scalars["UUID"]["input"]>
  inviter_auth_user_id?: InputMaybe<Scalars["UUID"]["input"]>
  name?: InputMaybe<Scalars["String"]["input"]>
  org_id?: InputMaybe<Scalars["UUID"]["input"]>
  phone_number?: InputMaybe<Scalars["String"]["input"]>
  role?: InputMaybe<Scalars["String"]["input"]>
}

export type InvitesOrderBy = {
  accepted?: InputMaybe<OrderByDirection>
  accepted_at?: InputMaybe<OrderByDirection>
  auth_user_id?: InputMaybe<OrderByDirection>
  created_at?: InputMaybe<OrderByDirection>
  email?: InputMaybe<OrderByDirection>
  expired_at?: InputMaybe<OrderByDirection>
  invite_id?: InputMaybe<OrderByDirection>
  inviter_auth_user_id?: InputMaybe<OrderByDirection>
  name?: InputMaybe<OrderByDirection>
  org_id?: InputMaybe<OrderByDirection>
  phone_number?: InputMaybe<OrderByDirection>
  role?: InputMaybe<OrderByDirection>
}

export type InvitesUpdateInput = {
  accepted?: InputMaybe<Scalars["Boolean"]["input"]>
  accepted_at?: InputMaybe<Scalars["Datetime"]["input"]>
  auth_user_id?: InputMaybe<Scalars["UUID"]["input"]>
  created_at?: InputMaybe<Scalars["Datetime"]["input"]>
  email?: InputMaybe<Scalars["String"]["input"]>
  expired_at?: InputMaybe<Scalars["Datetime"]["input"]>
  invite_id?: InputMaybe<Scalars["UUID"]["input"]>
  inviter_auth_user_id?: InputMaybe<Scalars["UUID"]["input"]>
  name?: InputMaybe<Scalars["String"]["input"]>
  org_id?: InputMaybe<Scalars["UUID"]["input"]>
  phone_number?: InputMaybe<Scalars["String"]["input"]>
  role?: InputMaybe<Scalars["String"]["input"]>
}

export type Message_GroupsFilter = {
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<Message_GroupsFilter>>
  channel_type?: InputMaybe<StringFilter>
  created_at?: InputMaybe<DatetimeFilter>
  group_id?: InputMaybe<UuidFilter>
  last_message_timestamp?: InputMaybe<BigIntFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<Message_GroupsFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<Message_GroupsFilter>>
  org_id?: InputMaybe<UuidFilter>
  participants_hash?: InputMaybe<StringFilter>
  provider_handle?: InputMaybe<StringFilter>
  provider_type?: InputMaybe<StringFilter>
}

export type Message_GroupsInsertInput = {
  channel_type?: InputMaybe<Scalars["String"]["input"]>
  created_at?: InputMaybe<Scalars["Datetime"]["input"]>
  group_id?: InputMaybe<Scalars["UUID"]["input"]>
  last_message_timestamp?: InputMaybe<Scalars["BigInt"]["input"]>
  org_id?: InputMaybe<Scalars["UUID"]["input"]>
  participants_hash?: InputMaybe<Scalars["String"]["input"]>
  provider_handle?: InputMaybe<Scalars["String"]["input"]>
  provider_type?: InputMaybe<Scalars["String"]["input"]>
}

export type Message_GroupsOrderBy = {
  channel_type?: InputMaybe<OrderByDirection>
  created_at?: InputMaybe<OrderByDirection>
  group_id?: InputMaybe<OrderByDirection>
  last_message_timestamp?: InputMaybe<OrderByDirection>
  org_id?: InputMaybe<OrderByDirection>
  participants_hash?: InputMaybe<OrderByDirection>
  provider_handle?: InputMaybe<OrderByDirection>
  provider_type?: InputMaybe<OrderByDirection>
}

export type Message_GroupsUpdateInput = {
  channel_type?: InputMaybe<Scalars["String"]["input"]>
  created_at?: InputMaybe<Scalars["Datetime"]["input"]>
  group_id?: InputMaybe<Scalars["UUID"]["input"]>
  last_message_timestamp?: InputMaybe<Scalars["BigInt"]["input"]>
  org_id?: InputMaybe<Scalars["UUID"]["input"]>
  participants_hash?: InputMaybe<Scalars["String"]["input"]>
  provider_handle?: InputMaybe<Scalars["String"]["input"]>
  provider_type?: InputMaybe<Scalars["String"]["input"]>
}

export type MessagesFilter = {
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<MessagesFilter>>
  channel_type?: InputMaybe<StringFilter>
  created_at?: InputMaybe<DatetimeFilter>
  extraction_message_id?: InputMaybe<UuidFilter>
  group_id?: InputMaybe<UuidFilter>
  message?: InputMaybe<StringFilter>
  message_id?: InputMaybe<UuidFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<MessagesFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<MessagesFilter>>
  org_id?: InputMaybe<UuidFilter>
  provider_handle?: InputMaybe<StringFilter>
  provider_type?: InputMaybe<StringFilter>
  raw_content?: InputMaybe<StringFilter>
  sender_id?: InputMaybe<UuidFilter>
  subject?: InputMaybe<StringFilter>
}

export type MessagesInsertInput = {
  channel_type?: InputMaybe<Scalars["String"]["input"]>
  created_at?: InputMaybe<Scalars["Datetime"]["input"]>
  extraction_message_id?: InputMaybe<Scalars["UUID"]["input"]>
  group_id?: InputMaybe<Scalars["UUID"]["input"]>
  message?: InputMaybe<Scalars["String"]["input"]>
  message_id?: InputMaybe<Scalars["UUID"]["input"]>
  metadata?: InputMaybe<Scalars["JSON"]["input"]>
  org_id?: InputMaybe<Scalars["UUID"]["input"]>
  provider_handle?: InputMaybe<Scalars["String"]["input"]>
  provider_type?: InputMaybe<Scalars["String"]["input"]>
  raw_content?: InputMaybe<Scalars["String"]["input"]>
  sender_id?: InputMaybe<Scalars["UUID"]["input"]>
  subject?: InputMaybe<Scalars["String"]["input"]>
}

export type MessagesOrderBy = {
  channel_type?: InputMaybe<OrderByDirection>
  created_at?: InputMaybe<OrderByDirection>
  extraction_message_id?: InputMaybe<OrderByDirection>
  group_id?: InputMaybe<OrderByDirection>
  message?: InputMaybe<OrderByDirection>
  message_id?: InputMaybe<OrderByDirection>
  org_id?: InputMaybe<OrderByDirection>
  provider_handle?: InputMaybe<OrderByDirection>
  provider_type?: InputMaybe<OrderByDirection>
  raw_content?: InputMaybe<OrderByDirection>
  sender_id?: InputMaybe<OrderByDirection>
  subject?: InputMaybe<OrderByDirection>
}

export type MessagesUpdateInput = {
  channel_type?: InputMaybe<Scalars["String"]["input"]>
  created_at?: InputMaybe<Scalars["Datetime"]["input"]>
  extraction_message_id?: InputMaybe<Scalars["UUID"]["input"]>
  group_id?: InputMaybe<Scalars["UUID"]["input"]>
  message?: InputMaybe<Scalars["String"]["input"]>
  message_id?: InputMaybe<Scalars["UUID"]["input"]>
  metadata?: InputMaybe<Scalars["JSON"]["input"]>
  org_id?: InputMaybe<Scalars["UUID"]["input"]>
  provider_handle?: InputMaybe<Scalars["String"]["input"]>
  provider_type?: InputMaybe<Scalars["String"]["input"]>
  raw_content?: InputMaybe<Scalars["String"]["input"]>
  sender_id?: InputMaybe<Scalars["UUID"]["input"]>
  subject?: InputMaybe<Scalars["String"]["input"]>
}

export type Org_UsersFilter = {
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<Org_UsersFilter>>
  auth_user_id?: InputMaybe<UuidFilter>
  is_whitelisted?: InputMaybe<BooleanFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<Org_UsersFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<Org_UsersFilter>>
  org_id?: InputMaybe<UuidFilter>
  profile_id?: InputMaybe<UuidFilter>
  role?: InputMaybe<StringFilter>
}

export type Org_UsersInsertInput = {
  auth_user_id?: InputMaybe<Scalars["UUID"]["input"]>
  is_whitelisted?: InputMaybe<Scalars["Boolean"]["input"]>
  org_id?: InputMaybe<Scalars["UUID"]["input"]>
  profile_id?: InputMaybe<Scalars["UUID"]["input"]>
  role?: InputMaybe<Scalars["String"]["input"]>
}

export type Org_UsersOrderBy = {
  auth_user_id?: InputMaybe<OrderByDirection>
  is_whitelisted?: InputMaybe<OrderByDirection>
  org_id?: InputMaybe<OrderByDirection>
  profile_id?: InputMaybe<OrderByDirection>
  role?: InputMaybe<OrderByDirection>
}

export type Org_UsersUpdateInput = {
  auth_user_id?: InputMaybe<Scalars["UUID"]["input"]>
  is_whitelisted?: InputMaybe<Scalars["Boolean"]["input"]>
  org_id?: InputMaybe<Scalars["UUID"]["input"]>
  profile_id?: InputMaybe<Scalars["UUID"]["input"]>
  role?: InputMaybe<Scalars["String"]["input"]>
}

export type OrganizationsFilter = {
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<OrganizationsFilter>>
  created_at?: InputMaybe<DatetimeFilter>
  name?: InputMaybe<StringFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<OrganizationsFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<OrganizationsFilter>>
  org_id?: InputMaybe<UuidFilter>
}

export type OrganizationsInsertInput = {
  created_at?: InputMaybe<Scalars["Datetime"]["input"]>
  name?: InputMaybe<Scalars["String"]["input"]>
  org_id?: InputMaybe<Scalars["UUID"]["input"]>
}

export type OrganizationsOrderBy = {
  created_at?: InputMaybe<OrderByDirection>
  name?: InputMaybe<OrderByDirection>
  org_id?: InputMaybe<OrderByDirection>
}

export type OrganizationsUpdateInput = {
  created_at?: InputMaybe<Scalars["Datetime"]["input"]>
  name?: InputMaybe<Scalars["String"]["input"]>
  org_id?: InputMaybe<Scalars["UUID"]["input"]>
}

export type ProfilesFilter = {
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<ProfilesFilter>>
  auth_user_id?: InputMaybe<UuidFilter>
  channel_handle?: InputMaybe<StringFilter>
  channel_type?: InputMaybe<StringFilter>
  company?: InputMaybe<StringFilter>
  created_at?: InputMaybe<DatetimeFilter>
  email?: InputMaybe<StringFilter>
  is_active?: InputMaybe<BooleanFilter>
  name?: InputMaybe<StringFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<ProfilesFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<ProfilesFilter>>
  org_id?: InputMaybe<UuidFilter>
  phone_number?: InputMaybe<StringFilter>
  primary_profile?: InputMaybe<BooleanFilter>
  title?: InputMaybe<StringFilter>
  user_id?: InputMaybe<UuidFilter>
}

export type ProfilesInsertInput = {
  auth_user_id?: InputMaybe<Scalars["UUID"]["input"]>
  channel_handle?: InputMaybe<Scalars["String"]["input"]>
  channel_type?: InputMaybe<Scalars["String"]["input"]>
  company?: InputMaybe<Scalars["String"]["input"]>
  created_at?: InputMaybe<Scalars["Datetime"]["input"]>
  email?: InputMaybe<Scalars["String"]["input"]>
  is_active?: InputMaybe<Scalars["Boolean"]["input"]>
  name?: InputMaybe<Scalars["String"]["input"]>
  org_id?: InputMaybe<Scalars["UUID"]["input"]>
  phone_number?: InputMaybe<Scalars["String"]["input"]>
  primary_profile?: InputMaybe<Scalars["Boolean"]["input"]>
  title?: InputMaybe<Scalars["String"]["input"]>
  user_id?: InputMaybe<Scalars["UUID"]["input"]>
}

export type ProfilesOrderBy = {
  auth_user_id?: InputMaybe<OrderByDirection>
  channel_handle?: InputMaybe<OrderByDirection>
  channel_type?: InputMaybe<OrderByDirection>
  company?: InputMaybe<OrderByDirection>
  created_at?: InputMaybe<OrderByDirection>
  email?: InputMaybe<OrderByDirection>
  is_active?: InputMaybe<OrderByDirection>
  name?: InputMaybe<OrderByDirection>
  org_id?: InputMaybe<OrderByDirection>
  phone_number?: InputMaybe<OrderByDirection>
  primary_profile?: InputMaybe<OrderByDirection>
  title?: InputMaybe<OrderByDirection>
  user_id?: InputMaybe<OrderByDirection>
}

export type ProfilesUpdateInput = {
  auth_user_id?: InputMaybe<Scalars["UUID"]["input"]>
  channel_handle?: InputMaybe<Scalars["String"]["input"]>
  channel_type?: InputMaybe<Scalars["String"]["input"]>
  company?: InputMaybe<Scalars["String"]["input"]>
  created_at?: InputMaybe<Scalars["Datetime"]["input"]>
  email?: InputMaybe<Scalars["String"]["input"]>
  is_active?: InputMaybe<Scalars["Boolean"]["input"]>
  name?: InputMaybe<Scalars["String"]["input"]>
  org_id?: InputMaybe<Scalars["UUID"]["input"]>
  phone_number?: InputMaybe<Scalars["String"]["input"]>
  primary_profile?: InputMaybe<Scalars["Boolean"]["input"]>
  title?: InputMaybe<Scalars["String"]["input"]>
  user_id?: InputMaybe<Scalars["UUID"]["input"]>
}

export type Thread_MessagesFilter = {
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<Thread_MessagesFilter>>
  created_at?: InputMaybe<DatetimeFilter>
  message_id?: InputMaybe<UuidFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<Thread_MessagesFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<Thread_MessagesFilter>>
  org_id?: InputMaybe<UuidFilter>
  thread_uid?: InputMaybe<UuidFilter>
}

export type Thread_MessagesInsertInput = {
  created_at?: InputMaybe<Scalars["Datetime"]["input"]>
  message_id?: InputMaybe<Scalars["UUID"]["input"]>
  org_id?: InputMaybe<Scalars["UUID"]["input"]>
  thread_uid?: InputMaybe<Scalars["UUID"]["input"]>
}

export type Thread_MessagesOrderBy = {
  created_at?: InputMaybe<OrderByDirection>
  message_id?: InputMaybe<OrderByDirection>
  org_id?: InputMaybe<OrderByDirection>
  thread_uid?: InputMaybe<OrderByDirection>
}

export type Thread_MessagesUpdateInput = {
  created_at?: InputMaybe<Scalars["Datetime"]["input"]>
  message_id?: InputMaybe<Scalars["UUID"]["input"]>
  org_id?: InputMaybe<Scalars["UUID"]["input"]>
  thread_uid?: InputMaybe<Scalars["UUID"]["input"]>
}

export type ThreadsFilter = {
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<ThreadsFilter>>
  created_at?: InputMaybe<DatetimeFilter>
  description?: InputMaybe<StringFilter>
  group_id?: InputMaybe<UuidFilter>
  last_message_timestamp?: InputMaybe<DatetimeFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<ThreadsFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<ThreadsFilter>>
  org_id?: InputMaybe<UuidFilter>
  thread_uid?: InputMaybe<UuidFilter>
  title?: InputMaybe<StringFilter>
}

export type ThreadsInsertInput = {
  created_at?: InputMaybe<Scalars["Datetime"]["input"]>
  description?: InputMaybe<Scalars["String"]["input"]>
  group_id?: InputMaybe<Scalars["UUID"]["input"]>
  last_message_timestamp?: InputMaybe<Scalars["Datetime"]["input"]>
  org_id?: InputMaybe<Scalars["UUID"]["input"]>
  thread_uid?: InputMaybe<Scalars["UUID"]["input"]>
  title?: InputMaybe<Scalars["String"]["input"]>
}

export type ThreadsOrderBy = {
  created_at?: InputMaybe<OrderByDirection>
  description?: InputMaybe<OrderByDirection>
  group_id?: InputMaybe<OrderByDirection>
  last_message_timestamp?: InputMaybe<OrderByDirection>
  org_id?: InputMaybe<OrderByDirection>
  thread_uid?: InputMaybe<OrderByDirection>
  title?: InputMaybe<OrderByDirection>
}

export type ThreadsUpdateInput = {
  created_at?: InputMaybe<Scalars["Datetime"]["input"]>
  description?: InputMaybe<Scalars["String"]["input"]>
  group_id?: InputMaybe<Scalars["UUID"]["input"]>
  last_message_timestamp?: InputMaybe<Scalars["Datetime"]["input"]>
  org_id?: InputMaybe<Scalars["UUID"]["input"]>
  thread_uid?: InputMaybe<Scalars["UUID"]["input"]>
  title?: InputMaybe<Scalars["String"]["input"]>
}

export type User_DashboardFilter = {
  additional_emails?: InputMaybe<StringListFilter>
  all_profile_ids?: InputMaybe<UuidListFilter>
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<User_DashboardFilter>>
  auth_user_id?: InputMaybe<UuidFilter>
  available_providers?: InputMaybe<StringListFilter>
  email?: InputMaybe<StringFilter>
  full_name?: InputMaybe<StringFilter>
  invite_accepted?: InputMaybe<BooleanFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<User_DashboardFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<User_DashboardFilter>>
  org_id?: InputMaybe<UuidFilter>
  role?: InputMaybe<StringFilter>
  user_id?: InputMaybe<UuidFilter>
}

export type User_DashboardInsertInput = {
  additional_emails?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
  all_profile_ids?: InputMaybe<Array<InputMaybe<Scalars["UUID"]["input"]>>>
  auth_user_id?: InputMaybe<Scalars["UUID"]["input"]>
  available_providers?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >
  email?: InputMaybe<Scalars["String"]["input"]>
  full_name?: InputMaybe<Scalars["String"]["input"]>
  integrations?: InputMaybe<Scalars["JSON"]["input"]>
  invite_accepted?: InputMaybe<Scalars["Boolean"]["input"]>
  org_id?: InputMaybe<Scalars["UUID"]["input"]>
  role?: InputMaybe<Scalars["String"]["input"]>
  user_id?: InputMaybe<Scalars["UUID"]["input"]>
}

export type User_DashboardOrderBy = {
  auth_user_id?: InputMaybe<OrderByDirection>
  email?: InputMaybe<OrderByDirection>
  full_name?: InputMaybe<OrderByDirection>
  invite_accepted?: InputMaybe<OrderByDirection>
  org_id?: InputMaybe<OrderByDirection>
  role?: InputMaybe<OrderByDirection>
  user_id?: InputMaybe<OrderByDirection>
}

export type User_DashboardUpdateInput = {
  additional_emails?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
  all_profile_ids?: InputMaybe<Array<InputMaybe<Scalars["UUID"]["input"]>>>
  auth_user_id?: InputMaybe<Scalars["UUID"]["input"]>
  available_providers?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >
  email?: InputMaybe<Scalars["String"]["input"]>
  full_name?: InputMaybe<Scalars["String"]["input"]>
  integrations?: InputMaybe<Scalars["JSON"]["input"]>
  invite_accepted?: InputMaybe<Scalars["Boolean"]["input"]>
  org_id?: InputMaybe<Scalars["UUID"]["input"]>
  role?: InputMaybe<Scalars["String"]["input"]>
  user_id?: InputMaybe<Scalars["UUID"]["input"]>
}

export type User_IntegrationsFilter = {
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<User_IntegrationsFilter>>
  auth_user_id?: InputMaybe<UuidFilter>
  channel?: InputMaybe<StringFilter>
  created_at?: InputMaybe<DatetimeFilter>
  external_connection_id?: InputMaybe<StringFilter>
  id?: InputMaybe<UuidFilter>
  is_active?: InputMaybe<BooleanFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<User_IntegrationsFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<User_IntegrationsFilter>>
  org_id?: InputMaybe<UuidFilter>
  provider?: InputMaybe<StringFilter>
  status?: InputMaybe<StringFilter>
  updated_at?: InputMaybe<DatetimeFilter>
}

export type User_IntegrationsInsertInput = {
  auth_user_id?: InputMaybe<Scalars["UUID"]["input"]>
  channel?: InputMaybe<Scalars["String"]["input"]>
  created_at?: InputMaybe<Scalars["Datetime"]["input"]>
  external_connection_id?: InputMaybe<Scalars["String"]["input"]>
  id?: InputMaybe<Scalars["UUID"]["input"]>
  is_active?: InputMaybe<Scalars["Boolean"]["input"]>
  metadata?: InputMaybe<Scalars["JSON"]["input"]>
  org_id?: InputMaybe<Scalars["UUID"]["input"]>
  provider?: InputMaybe<Scalars["String"]["input"]>
  status?: InputMaybe<Scalars["String"]["input"]>
  updated_at?: InputMaybe<Scalars["Datetime"]["input"]>
}

export type User_IntegrationsOrderBy = {
  auth_user_id?: InputMaybe<OrderByDirection>
  channel?: InputMaybe<OrderByDirection>
  created_at?: InputMaybe<OrderByDirection>
  external_connection_id?: InputMaybe<OrderByDirection>
  id?: InputMaybe<OrderByDirection>
  is_active?: InputMaybe<OrderByDirection>
  org_id?: InputMaybe<OrderByDirection>
  provider?: InputMaybe<OrderByDirection>
  status?: InputMaybe<OrderByDirection>
  updated_at?: InputMaybe<OrderByDirection>
}

export type User_IntegrationsUpdateInput = {
  auth_user_id?: InputMaybe<Scalars["UUID"]["input"]>
  channel?: InputMaybe<Scalars["String"]["input"]>
  created_at?: InputMaybe<Scalars["Datetime"]["input"]>
  external_connection_id?: InputMaybe<Scalars["String"]["input"]>
  id?: InputMaybe<Scalars["UUID"]["input"]>
  is_active?: InputMaybe<Scalars["Boolean"]["input"]>
  metadata?: InputMaybe<Scalars["JSON"]["input"]>
  org_id?: InputMaybe<Scalars["UUID"]["input"]>
  provider?: InputMaybe<Scalars["String"]["input"]>
  status?: InputMaybe<Scalars["String"]["input"]>
  updated_at?: InputMaybe<Scalars["Datetime"]["input"]>
}

export type Worm_ReferencesFilter = {
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<Worm_ReferencesFilter>>
  created_at?: InputMaybe<DatetimeFilter>
  group_id?: InputMaybe<UuidFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<Worm_ReferencesFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<Worm_ReferencesFilter>>
  org_id?: InputMaybe<UuidFilter>
  original_record_id?: InputMaybe<UuidFilter>
  record_id?: InputMaybe<UuidFilter>
  record_type?: InputMaybe<StringFilter>
  worm_path?: InputMaybe<StringFilter>
}

export type Worm_ReferencesInsertInput = {
  created_at?: InputMaybe<Scalars["Datetime"]["input"]>
  group_id?: InputMaybe<Scalars["UUID"]["input"]>
  org_id?: InputMaybe<Scalars["UUID"]["input"]>
  original_record_id?: InputMaybe<Scalars["UUID"]["input"]>
  record_id?: InputMaybe<Scalars["UUID"]["input"]>
  record_type?: InputMaybe<Scalars["String"]["input"]>
  worm_path?: InputMaybe<Scalars["String"]["input"]>
}

export type Worm_ReferencesOrderBy = {
  created_at?: InputMaybe<OrderByDirection>
  group_id?: InputMaybe<OrderByDirection>
  org_id?: InputMaybe<OrderByDirection>
  original_record_id?: InputMaybe<OrderByDirection>
  record_id?: InputMaybe<OrderByDirection>
  record_type?: InputMaybe<OrderByDirection>
  worm_path?: InputMaybe<OrderByDirection>
}

export type Worm_ReferencesUpdateInput = {
  created_at?: InputMaybe<Scalars["Datetime"]["input"]>
  group_id?: InputMaybe<Scalars["UUID"]["input"]>
  org_id?: InputMaybe<Scalars["UUID"]["input"]>
  original_record_id?: InputMaybe<Scalars["UUID"]["input"]>
  record_id?: InputMaybe<Scalars["UUID"]["input"]>
  record_type?: InputMaybe<Scalars["String"]["input"]>
  worm_path?: InputMaybe<Scalars["String"]["input"]>
}

export type Worm_Sync_IssuesFilter = {
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<Worm_Sync_IssuesFilter>>
  detected_at?: InputMaybe<DatetimeFilter>
  error_message?: InputMaybe<StringFilter>
  issue_id?: InputMaybe<UuidFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<Worm_Sync_IssuesFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<Worm_Sync_IssuesFilter>>
  record_type?: InputMaybe<StringFilter>
  resolution_notes?: InputMaybe<StringFilter>
  resolved_at?: InputMaybe<DatetimeFilter>
}

export type Worm_Sync_IssuesInsertInput = {
  detected_at?: InputMaybe<Scalars["Datetime"]["input"]>
  error_message?: InputMaybe<Scalars["String"]["input"]>
  issue_id?: InputMaybe<Scalars["UUID"]["input"]>
  record_type?: InputMaybe<Scalars["String"]["input"]>
  resolution_notes?: InputMaybe<Scalars["String"]["input"]>
  resolved_at?: InputMaybe<Scalars["Datetime"]["input"]>
}

export type Worm_Sync_IssuesOrderBy = {
  detected_at?: InputMaybe<OrderByDirection>
  error_message?: InputMaybe<OrderByDirection>
  issue_id?: InputMaybe<OrderByDirection>
  record_type?: InputMaybe<OrderByDirection>
  resolution_notes?: InputMaybe<OrderByDirection>
  resolved_at?: InputMaybe<OrderByDirection>
}

export type Worm_Sync_IssuesUpdateInput = {
  detected_at?: InputMaybe<Scalars["Datetime"]["input"]>
  error_message?: InputMaybe<Scalars["String"]["input"]>
  issue_id?: InputMaybe<Scalars["UUID"]["input"]>
  record_type?: InputMaybe<Scalars["String"]["input"]>
  resolution_notes?: InputMaybe<Scalars["String"]["input"]>
  resolved_at?: InputMaybe<Scalars["Datetime"]["input"]>
}

export type GetCountOrgUsersQueryVariables = Exact<{[key: string]: never}>

export type GetCountOrgUsersQuery = {
  __typename: "Query"
  org_usersCollection?: {
    __typename: "org_usersConnection"
    edges: Array<{
      __typename: "org_usersEdge"
      node: {
        __typename: "org_users"
        profile_id?: string | null
        auth_user_id: string
        profiles?: {__typename: "profiles"; name?: string | null} | null
      }
    }>
  } | null
}

export type GetOrgUsersQueryVariables = Exact<{[key: string]: never}>

export type GetOrgUsersQuery = {
  __typename: "Query"
  org_usersCollection?: {
    __typename: "org_usersConnection"
    edges: Array<{
      __typename: "org_usersEdge"
      node: {
        __typename: "org_users"
        profile_id?: string | null
        auth_user_id: string
        profiles?: {__typename: "profiles"; name?: string | null} | null
      }
    }>
  } | null
}

export type GetEmailThreadQueryVariables = Exact<{
  filter?: InputMaybe<ThreadsFilter>
  orderBy?: InputMaybe<Array<Thread_MessagesOrderBy> | Thread_MessagesOrderBy>
}>

export type GetEmailThreadQuery = {
  __typename: "Query"
  threadsCollection?: {
    __typename: "threadsConnection"
    edges: Array<{
      __typename: "threadsEdge"
      node: {
        __typename: "threads"
        thread_uid: string
        created_at?: string | null
        title?: string | null
        last_message_timestamp?: string | null
        description?: string | null
        message_groups?: {
          __typename: "message_groups"
          channel_type?: string | null
          group_participantsCollection?: {
            __typename: "group_participantsConnection"
            edges: Array<{
              __typename: "group_participantsEdge"
              node: {
                __typename: "group_participants"
                profiles?: {
                  __typename: "profiles"
                  auth_user_id?: string | null
                  channel_type?: string | null
                  channel_handle?: string | null
                  company?: string | null
                  created_at?: string | null
                  email?: string | null
                  is_active?: boolean | null
                  name?: string | null
                  phone_number?: string | null
                  title?: string | null
                } | null
              }
            }>
          } | null
        } | null
        thread_messagesCollection?: {
          __typename: "thread_messagesConnection"
          edges: Array<{
            __typename: "thread_messagesEdge"
            node: {
              __typename: "thread_messages"
              messages?: {
                __typename: "messages"
                message: string
                raw_content?: string | null
                subject?: string | null
                metadata?: string | null
                channel_type?: string | null
                provider_handle?: string | null
                provider_type?: string | null
                created_at?: string | null
                profiles?: {
                  __typename: "profiles"
                  auth_user_id?: string | null
                  channel_type?: string | null
                  channel_handle?: string | null
                  company?: string | null
                  created_at?: string | null
                  email?: string | null
                  is_active?: boolean | null
                  name?: string | null
                  phone_number?: string | null
                  title?: string | null
                } | null
                attachmentsCollection?: {
                  __typename: "attachmentsConnection"
                  edges: Array<{
                    __typename: "attachmentsEdge"
                    node: {__typename: "attachments"; s3_uri: string}
                  }>
                } | null
              } | null
            }
          }>
        } | null
      }
    }>
  } | null
}

export type GetNavigationThreadsQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<ThreadsOrderBy> | ThreadsOrderBy>
  first?: InputMaybe<Scalars["Int"]["input"]>
}>

export type GetNavigationThreadsQuery = {
  __typename: "Query"
  threadsCollection?: {
    __typename: "threadsConnection"
    edges: Array<{
      __typename: "threadsEdge"
      node: {
        __typename: "threads"
        thread_uid: string
        created_at?: string | null
        title?: string | null
        last_message_timestamp?: string | null
        description?: string | null
        message_groups?: {
          __typename: "message_groups"
          channel_type?: string | null
          group_participantsCollection?: {
            __typename: "group_participantsConnection"
            edges: Array<{
              __typename: "group_participantsEdge"
              node: {
                __typename: "group_participants"
                profiles?: {
                  __typename: "profiles"
                  auth_user_id?: string | null
                  channel_type?: string | null
                  channel_handle?: string | null
                  company?: string | null
                  created_at?: string | null
                  email?: string | null
                  is_active?: boolean | null
                  name?: string | null
                  phone_number?: string | null
                  title?: string | null
                } | null
              }
            }>
          } | null
        } | null
      }
    }>
  } | null
}

export type GetThreadsQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars["Cursor"]["input"]>
}>

export type GetThreadsQuery = {
  __typename: "Query"
  threadsCollection?: {
    __typename: "threadsConnection"
    edges: Array<{
      __typename: "threadsEdge"
      node: {
        __typename: "threads"
        nodeId: string
        thread_uid: string
        org_id: string
        group_id: string
        created_at?: string | null
        title?: string | null
        description?: string | null
        last_message_timestamp?: string | null
        message_groups?: {
          __typename: "message_groups"
          channel_type?: string | null
          group_participantsCollection?: {
            __typename: "group_participantsConnection"
            edges: Array<{
              __typename: "group_participantsEdge"
              node: {
                __typename: "group_participants"
                group_id: string
                profiles?: {
                  __typename: "profiles"
                  auth_user_id?: string | null
                  channel_type?: string | null
                  channel_handle?: string | null
                  company?: string | null
                  created_at?: string | null
                  email?: string | null
                  is_active?: boolean | null
                  name?: string | null
                  phone_number?: string | null
                  title?: string | null
                } | null
              }
            }>
          } | null
        } | null
      }
    }>
    pageInfo: {
      __typename: "PageInfo"
      endCursor?: string | null
      hasNextPage: boolean
    }
  } | null
}

export type SearchedThreadsQueryVariables = Exact<{
  filter?: InputMaybe<ThreadsFilter>
}>

export type SearchedThreadsQuery = {
  __typename: "Query"
  threadsCollection?: {
    __typename: "threadsConnection"
    edges: Array<{
      __typename: "threadsEdge"
      node: {
        __typename: "threads"
        thread_uid: string
        created_at?: string | null
        title?: string | null
        last_message_timestamp?: string | null
        description?: string | null
        message_groups?: {
          __typename: "message_groups"
          channel_type?: string | null
          group_participantsCollection?: {
            __typename: "group_participantsConnection"
            edges: Array<{
              __typename: "group_participantsEdge"
              node: {
                __typename: "group_participants"
                profiles?: {
                  __typename: "profiles"
                  auth_user_id?: string | null
                  channel_type?: string | null
                  channel_handle?: string | null
                  company?: string | null
                  created_at?: string | null
                  email?: string | null
                  is_active?: boolean | null
                  name?: string | null
                  phone_number?: string | null
                  title?: string | null
                } | null
              }
            }>
          } | null
        } | null
      }
    }>
  } | null
}

export type GetMyUserDashboardQueryVariables = Exact<{
  auth_user_id: Scalars["UUID"]["input"]
}>

export type GetMyUserDashboardQuery = {
  __typename: "Query"
  user_dashboardCollection?: {
    __typename: "user_dashboardConnection"
    edges: Array<{
      __typename: "user_dashboardEdge"
      node: {
        __typename: "user_dashboard"
        email?: string | null
        full_name?: string | null
        available_providers?: Array<string | null> | null
        auth_user_id?: string | null
        invite_accepted?: boolean | null
        integrations?: string | null
        role?: string | null
        user_id?: string | null
        all_profile_ids?: Array<string | null> | null
        additional_emails?: Array<string | null> | null
      }
    }>
  } | null
}

export type GetOrgUsersDashboardQueryVariables = Exact<{
  auth_user_id: Scalars["UUID"]["input"]
}>

export type GetOrgUsersDashboardQuery = {
  __typename: "Query"
  user_dashboardCollection?: {
    __typename: "user_dashboardConnection"
    edges: Array<{
      __typename: "user_dashboardEdge"
      node: {
        __typename: "user_dashboard"
        email?: string | null
        full_name?: string | null
        available_providers?: Array<string | null> | null
        auth_user_id?: string | null
        invite_accepted?: boolean | null
        integrations?: string | null
        role?: string | null
        user_id?: string | null
        all_profile_ids?: Array<string | null> | null
        additional_emails?: Array<string | null> | null
      }
    }>
  } | null
}

export const GetCountOrgUsersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {kind: "Name", value: "GetCountOrgUsers"},
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {kind: "Field", name: {kind: "Name", value: "__typename"}},
          {
            kind: "Field",
            name: {kind: "Name", value: "org_usersCollection"},
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {kind: "Field", name: {kind: "Name", value: "__typename"}},
                {
                  kind: "Field",
                  name: {kind: "Name", value: "edges"},
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {kind: "Name", value: "__typename"},
                      },
                      {
                        kind: "Field",
                        name: {kind: "Name", value: "node"},
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "__typename"},
                            },
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "profile_id"},
                            },
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "auth_user_id"},
                            },
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "profiles"},
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {kind: "Name", value: "__typename"},
                                  },
                                  {
                                    kind: "Field",
                                    name: {kind: "Name", value: "name"},
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCountOrgUsersQuery,
  GetCountOrgUsersQueryVariables
>
export const GetOrgUsersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {kind: "Name", value: "GetOrgUsers"},
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {kind: "Field", name: {kind: "Name", value: "__typename"}},
          {
            kind: "Field",
            name: {kind: "Name", value: "org_usersCollection"},
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {kind: "Field", name: {kind: "Name", value: "__typename"}},
                {
                  kind: "Field",
                  name: {kind: "Name", value: "edges"},
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {kind: "Name", value: "__typename"},
                      },
                      {
                        kind: "Field",
                        name: {kind: "Name", value: "node"},
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "__typename"},
                            },
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "profile_id"},
                            },
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "auth_user_id"},
                            },
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "profiles"},
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {kind: "Name", value: "__typename"},
                                  },
                                  {
                                    kind: "Field",
                                    name: {kind: "Name", value: "name"},
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetOrgUsersQuery, GetOrgUsersQueryVariables>
export const GetEmailThreadDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {kind: "Name", value: "getEmailThread"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "filter"}},
          type: {
            kind: "NamedType",
            name: {kind: "Name", value: "threadsFilter"},
          },
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "orderBy"}},
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: {kind: "Name", value: "thread_messagesOrderBy"},
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {kind: "Field", name: {kind: "Name", value: "__typename"}},
          {
            kind: "Field",
            name: {kind: "Name", value: "threadsCollection"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "filter"},
                value: {
                  kind: "Variable",
                  name: {kind: "Name", value: "filter"},
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {kind: "Field", name: {kind: "Name", value: "__typename"}},
                {
                  kind: "Field",
                  name: {kind: "Name", value: "edges"},
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {kind: "Name", value: "__typename"},
                      },
                      {
                        kind: "Field",
                        name: {kind: "Name", value: "node"},
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "__typename"},
                            },
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "thread_uid"},
                            },
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "created_at"},
                            },
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "title"},
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "last_message_timestamp",
                              },
                            },
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "description"},
                            },
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "message_groups"},
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {kind: "Name", value: "__typename"},
                                  },
                                  {
                                    kind: "Field",
                                    name: {kind: "Name", value: "channel_type"},
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "group_participantsCollection",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {kind: "Name", value: "edges"},
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "__typename",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "node",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "__typename",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "profiles",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "__typename",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "auth_user_id",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "channel_type",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "channel_handle",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "company",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "created_at",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "email",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "is_active",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "name",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "phone_number",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "title",
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "thread_messagesCollection",
                              },
                              arguments: [
                                {
                                  kind: "Argument",
                                  name: {kind: "Name", value: "orderBy"},
                                  value: {
                                    kind: "Variable",
                                    name: {kind: "Name", value: "orderBy"},
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {kind: "Name", value: "__typename"},
                                  },
                                  {
                                    kind: "Field",
                                    name: {kind: "Name", value: "edges"},
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {kind: "Name", value: "node"},
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "__typename",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "messages",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "__typename",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "message",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "raw_content",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "subject",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "metadata",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "channel_type",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "provider_handle",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "provider_type",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "created_at",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "profiles",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "__typename",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "auth_user_id",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "channel_type",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "channel_handle",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "company",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "created_at",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "email",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "is_active",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "name",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "phone_number",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "title",
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "attachmentsCollection",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "__typename",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "edges",
                                                            },
                                                            selectionSet: {
                                                              kind: "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "__typename",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "node",
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: "SelectionSet",
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "__typename",
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "s3_uri",
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetEmailThreadQuery, GetEmailThreadQueryVariables>
export const GetNavigationThreadsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {kind: "Name", value: "getNavigationThreads"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "orderBy"}},
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: {kind: "Name", value: "threadsOrderBy"},
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "first"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "Int"}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {kind: "Field", name: {kind: "Name", value: "__typename"}},
          {
            kind: "Field",
            name: {kind: "Name", value: "threadsCollection"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "orderBy"},
                value: {
                  kind: "Variable",
                  name: {kind: "Name", value: "orderBy"},
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {kind: "Field", name: {kind: "Name", value: "__typename"}},
                {
                  kind: "Field",
                  name: {kind: "Name", value: "edges"},
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {kind: "Name", value: "__typename"},
                      },
                      {
                        kind: "Field",
                        name: {kind: "Name", value: "node"},
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "__typename"},
                            },
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "thread_uid"},
                            },
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "created_at"},
                            },
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "title"},
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "last_message_timestamp",
                              },
                            },
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "description"},
                            },
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "message_groups"},
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {kind: "Name", value: "__typename"},
                                  },
                                  {
                                    kind: "Field",
                                    name: {kind: "Name", value: "channel_type"},
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "group_participantsCollection",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {kind: "Name", value: "edges"},
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "__typename",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "node",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "__typename",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "profiles",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "__typename",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "auth_user_id",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "channel_type",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "channel_handle",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "company",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "created_at",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "email",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "is_active",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "name",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "phone_number",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "title",
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetNavigationThreadsQuery,
  GetNavigationThreadsQueryVariables
>
export const GetThreadsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {kind: "Name", value: "GetThreads"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "cursor"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "Cursor"}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {kind: "Field", name: {kind: "Name", value: "__typename"}},
          {
            kind: "Field",
            name: {kind: "Name", value: "threadsCollection"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "first"},
                value: {kind: "IntValue", value: "10"},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "after"},
                value: {
                  kind: "Variable",
                  name: {kind: "Name", value: "cursor"},
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {kind: "Field", name: {kind: "Name", value: "__typename"}},
                {
                  kind: "Field",
                  name: {kind: "Name", value: "edges"},
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {kind: "Name", value: "__typename"},
                      },
                      {
                        kind: "Field",
                        name: {kind: "Name", value: "node"},
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "__typename"},
                            },
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "nodeId"},
                            },
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "thread_uid"},
                            },
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "org_id"},
                            },
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "group_id"},
                            },
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "created_at"},
                            },
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "title"},
                            },
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "description"},
                            },
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "message_groups"},
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {kind: "Name", value: "__typename"},
                                  },
                                  {
                                    kind: "Field",
                                    name: {kind: "Name", value: "channel_type"},
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "group_participantsCollection",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {kind: "Name", value: "edges"},
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "__typename",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "node",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "__typename",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "group_id",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "profiles",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "__typename",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "auth_user_id",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "channel_type",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "channel_handle",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "company",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "created_at",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "email",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "is_active",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "name",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "phone_number",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "title",
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "last_message_timestamp",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: {kind: "Name", value: "pageInfo"},
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {kind: "Name", value: "__typename"},
                      },
                      {kind: "Field", name: {kind: "Name", value: "endCursor"}},
                      {
                        kind: "Field",
                        name: {kind: "Name", value: "hasNextPage"},
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetThreadsQuery, GetThreadsQueryVariables>
export const SearchedThreadsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {kind: "Name", value: "SearchedThreads"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "filter"}},
          type: {
            kind: "NamedType",
            name: {kind: "Name", value: "threadsFilter"},
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {kind: "Field", name: {kind: "Name", value: "__typename"}},
          {
            kind: "Field",
            name: {kind: "Name", value: "threadsCollection"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "filter"},
                value: {
                  kind: "Variable",
                  name: {kind: "Name", value: "filter"},
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {kind: "Field", name: {kind: "Name", value: "__typename"}},
                {
                  kind: "Field",
                  name: {kind: "Name", value: "edges"},
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {kind: "Name", value: "__typename"},
                      },
                      {
                        kind: "Field",
                        name: {kind: "Name", value: "node"},
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "__typename"},
                            },
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "thread_uid"},
                            },
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "created_at"},
                            },
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "title"},
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "last_message_timestamp",
                              },
                            },
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "description"},
                            },
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "message_groups"},
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {kind: "Name", value: "__typename"},
                                  },
                                  {
                                    kind: "Field",
                                    name: {kind: "Name", value: "channel_type"},
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "group_participantsCollection",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {kind: "Name", value: "edges"},
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "__typename",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "node",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "__typename",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "profiles",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "__typename",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "auth_user_id",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "channel_type",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "channel_handle",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "company",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "created_at",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "email",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "is_active",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "name",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "phone_number",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "title",
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SearchedThreadsQuery,
  SearchedThreadsQueryVariables
>
export const GetMyUserDashboardDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {kind: "Name", value: "GetMyUserDashboard"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: {kind: "Name", value: "auth_user_id"},
          },
          type: {
            kind: "NonNullType",
            type: {kind: "NamedType", name: {kind: "Name", value: "UUID"}},
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {kind: "Field", name: {kind: "Name", value: "__typename"}},
          {
            kind: "Field",
            name: {kind: "Name", value: "user_dashboardCollection"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "filter"},
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: {kind: "Name", value: "auth_user_id"},
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: {kind: "Name", value: "eq"},
                            value: {
                              kind: "Variable",
                              name: {kind: "Name", value: "auth_user_id"},
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {kind: "Field", name: {kind: "Name", value: "__typename"}},
                {
                  kind: "Field",
                  name: {kind: "Name", value: "edges"},
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {kind: "Name", value: "__typename"},
                      },
                      {
                        kind: "Field",
                        name: {kind: "Name", value: "node"},
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "__typename"},
                            },
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "email"},
                            },
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "full_name"},
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "available_providers",
                              },
                            },
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "auth_user_id"},
                            },
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "invite_accepted"},
                            },
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "integrations"},
                            },
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "role"},
                            },
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "user_id"},
                            },
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "all_profile_ids"},
                            },
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "additional_emails"},
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMyUserDashboardQuery,
  GetMyUserDashboardQueryVariables
>
export const GetOrgUsersDashboardDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {kind: "Name", value: "GetOrgUsersDashboard"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: {kind: "Name", value: "auth_user_id"},
          },
          type: {
            kind: "NonNullType",
            type: {kind: "NamedType", name: {kind: "Name", value: "UUID"}},
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {kind: "Field", name: {kind: "Name", value: "__typename"}},
          {
            kind: "Field",
            name: {kind: "Name", value: "user_dashboardCollection"},
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {kind: "Field", name: {kind: "Name", value: "__typename"}},
                {
                  kind: "Field",
                  name: {kind: "Name", value: "edges"},
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {kind: "Name", value: "__typename"},
                      },
                      {
                        kind: "Field",
                        name: {kind: "Name", value: "node"},
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "__typename"},
                            },
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "email"},
                            },
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "full_name"},
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "available_providers",
                              },
                            },
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "auth_user_id"},
                            },
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "invite_accepted"},
                            },
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "integrations"},
                            },
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "role"},
                            },
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "user_id"},
                            },
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "all_profile_ids"},
                            },
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "additional_emails"},
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrgUsersDashboardQuery,
  GetOrgUsersDashboardQueryVariables
>
