import {LockedFeatureIndicator} from "@/components/general/ui/LockedFeatureIndicator"
import ChannelIndicator from "../ChannelIndicator"

const TitleSections = ({
  title,
  description,
  provider,
}: {
  title: string
  description: string
  provider: string
}) => {
  return (
    <>
      {title ? (
        <>
          <ChannelIndicator
            channel_type="email"
            size="sm"
            text={`Email via ${provider.charAt(0).toUpperCase() + provider.slice(1)} Integration`}
          />
          <p className="text-xl font-medium">
            {title || "Ongoing Conversation"}
          </p>
        </>
      ) : (
        <div className="flex flex-row items-center gap-1">
          <LockedFeatureIndicator />
          <p className="text-xl font-medium text-neutral-300">
            AI-Generated Conversation Title{" "}
          </p>
        </div>
      )}
      {description ? (
        <p className="font-regular text-sm">
          {description ||
            "This is an ongoing conversation that Quartz will process once it has deemed that the conversation thread has ended"}
        </p>
      ) : (
        <></>
      )}
    </>
  )
}

export default TitleSections
