import supabase from "@/supabaseConfig"

const apiKey = process.env.REACT_APP_SUPABASE_ANON_KEY || ""
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL || ""

export const exportData = async ({orgId}: {orgId: string}): Promise<void> => {
  const session = await supabase.auth.getSession()
  console.log("session", session)
  const token = session.data.session?.access_token
  if (!apiKey || !supabaseUrl) {
    throw new Error("Supabase configuration is missing.")
  }

  if (!token) {
    throw new Error("No token found in session.")
  }
  console.log("exporting data")

  const response = await fetch(`${supabaseUrl}/functions/v1/export-data`, {
    method: "POST",
    headers: {
      apiKey: apiKey,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({orgId}),
  })

  if (!response.ok) {
    const errorDetails = await response.text()
    throw new Error(`Network response was not ok: ${errorDetails}`)
  }

  // Get the filename from the Content-Disposition header, or use a default
  const contentDisposition = response.headers.get("Content-Disposition")
  const filename = contentDisposition
    ? contentDisposition.split("filename=")[1].replace(/"/g, "")
    : `org_${orgId}_export.csv`

  // Create a blob from the response
  const blob = await response.blob()

  // Create a download link and trigger the download
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement("a")
  link.href = url
  link.download = filename
  document.body.appendChild(link)
  link.click()

  // Cleanup
  document.body.removeChild(link)
  window.URL.revokeObjectURL(url)
}
