import {twMerge} from "tailwind-merge"
import {IconX} from "@tabler/icons-react"
import {LockedFeatureIndicator} from "../../general/ui/LockedFeatureIndicator"

import ContactInfo from "../ContactInfo"
import {ThreadWithMessages} from "@/utils/graphQL/queries/threads/shared"
import {useQuery} from "@apollo/client"
import {GetEmailThreadDocument} from "@/gql/graphql"
import {EmailThread} from "@/utils/graphQL/queries/threads/shared"
import {useGetEmailThread} from "@/utils/graphQL/queries/threads/useGetEmailThread"
import {convertDate, convertTimestamp, getDuration} from "../utils"
import React from "react"
import ChannelIndicator from "../ChannelIndicator"
import ParticipantPreview from "./ParticipantPreview"
import TitleSections from "./TitleSections"
import DatesPreview from "./DatesPreview"
import MessageTableView from "./MessageTableView"
import EmailThreadSummary from "./EmailThreadSummary"

const EmailView = ({
  selectedThread,
  setSelectedThread,
}: {
  selectedThread: string
  setSelectedThread: (thread_uid: string) => void
}) => {
  const {
    data,
    title,
    subject,
    description,
    lastTimestamp,
    createdAt,
    threadParticipants,
    provider,
    messages,
    loading,
    error,
  } = useGetEmailThread(selectedThread)

  React.useEffect(() => {
    if (loading) return
    if (error) return
    if (!data) return
    // console.log("🔍 Email thread data", {data, loading, error})
  }, [data, loading, error])

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    // console.log("🔍 Email thread data", e)
  }

  return (
    <div
      className={twMerge(
        "h-full max-w-[70%] overflow-scroll rounded-md border border-black/5 transition-all duration-150 scrollbar-hide",
        selectedThread ? "w-[70%] opacity-100" : "w-[0%] opacity-0"
      )}
      onScroll={handleScroll}
    >
      <div className="top-0 flex flex-col gap-2 border-b border-black/5 bg-white px-2 py-1 pb-3 pl-4">
        <IconX
          color="red"
          className="mt-1 h-4 w-4 self-end rounded-full bg-danger-100 p-[2px]"
          onClick={() => setSelectedThread(null)}
        />
        <TitleSections
          title={title || subject}
          description={description}
          provider={provider}
        />
        <EmailThreadSummary thread_uid={""} />
        <DatesPreview createdAt={createdAt} lastTimestamp={lastTimestamp} />
        {/* <ParticipantPreview threadParticipants={threadParticipants} /> */}
      </div>
      <div className="px-4 py-2">
        <p className=" w-fit rounded-md border border-black/5 bg-white px-1 py-1 text-xs font-medium ">
          Messages:
        </p>
      </div>
      <MessageTableView messages={messages} />
    </div>
  )
}

export default EmailView
