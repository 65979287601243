import {Profile} from "@/utils/graphQL/queries/threads/shared"

export function createParticipantArray(
  profiles: Profile[],
  channel_type?: string | undefined
): string[] {
  if (!profiles) {
    console.error("No profiles given to createParticipantArray")
    return []
  }
  if (profiles.length === 0) {
    return []
  }
  switch (channel_type) {
    case "email":
      return profiles.map((profile) => profile.email)
    case "phone":
      return profiles.map((profile) => profile.phone_number)
    default:
      return profiles.map(
        (profile) =>
          profile?.name ||
          profile?.channel_handle ||
          profile?.phone_number ||
          profile?.email ||
          ""
      )
  }
}

export function convertDate(date: any | undefined) {
  // takes fb timestamp string and returns date
  if (date) {
    return new Date(date).toLocaleDateString()
  }
  return "N/A"
}

export function convertTimestamp(timestamp: any | undefined) {
  // takes fb timestamp string and returns 24 hour time
  // timestamp = new Date(timestamp)
  if (timestamp) {
    return new Date(timestamp).toISOString()
  }
  return "N/A"
}

export function getDuration(start: string, end: string) {
  const startDate = new Date(start)
  const endDate = new Date(end)
  const duration = endDate.getTime() - startDate.getTime()
  const hours = Math.floor(duration / (1000 * 60 * 60))
  const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60))
  return `${hours}h ${minutes}m`
}
