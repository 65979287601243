import {useEffect, useState, useMemo} from "react"
import {searchMessages} from "./searchMessages" // <— the function above

export function useSearchMessages(
  searchTerm?: string,
  dateRanges?: {start: string; end: string}[],
  participantProfileIds?: string[],
  page = 0,
  limit = 100
) {
  const [data, setData] = useState<
    {thread_uid: string; message_id: string}[] | null
  >(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const [hasMore, setHasMore] = useState(false)

  // Memoize the search parameters to ensure stable reference
  const searchParams = useMemo(
    () => ({
      searchTerm,
      dateRanges,
      participantProfileIds,
      page,
      limit,
    }),
    [searchTerm, dateRanges, participantProfileIds, page, limit]
  )

  // Memoize the empty check to ensure stable reference
  const isEmpty = useMemo(
    () => !searchTerm && !dateRanges?.length && !participantProfileIds?.length,
    [searchTerm, dateRanges, participantProfileIds]
  )

  useEffect(() => {
    console.info("🔍 Search params:", searchParams)

    // If empty, set data once and don't trigger search
    if (isEmpty) {
      console.info("⏭️ Skipping search - no filters or search term provided")
      setData([])
      setIsLoading(false)
      setError(null)
      setHasMore(false)
      return
    }

    const doSearch = async () => {
      console.info("🚀 Starting search...")
      setIsLoading(true)
      setError(null)
      try {
        const result = await searchMessages(searchParams)
        if (result.error) {
          console.info("❌ Search failed:", result.error)
          setError(result.error)
        } else {
          console.info("✅ Search completed:", {
            resultsCount: result.data?.length,
            hasMore: result.hasMore,
          })
          setData(result.data)
          setHasMore(result.hasMore)
        }
      } catch (err) {
        console.error("❌ Search error:", err)
        setError(
          err instanceof Error ? err : new Error("An unknown error occurred")
        )
      } finally {
        setIsLoading(false)
        console.info("🏁 Search state:", {
          isLoading: false,
          hasError: error !== null,
        })
      }
    }

    doSearch()
  }, [searchParams, isEmpty]) // Only depend on memoized values

  const result = useMemo(
    () => ({
      data,
      error,
      isLoading,
      hasMore,
    }),
    [data, error, isLoading, hasMore]
  )

  return result
}
