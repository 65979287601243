import {ThreadWithProfiles} from "@/utils/graphQL/queries/threads/shared"
import NavigatorTableViewTicketItem from "./NavigatorTableViewTicketItem"
import {createParticipantArray} from "./utils"
import {twMerge} from "tailwind-merge"

interface NavigatorTableViewProps {
  threads: ThreadWithProfiles[]
  date: string
  setSelectedThread: (thread_uid: string) => void
  selectedThread: string
  mode: "month-view" | "search-results"
}

export const NavigatorTableView = ({
  threads,
  date,
  setSelectedThread,
  selectedThread,
  mode,
}: NavigatorTableViewProps) => {
  return (
    <div className="grid w-full grid-cols-5">
      <div
        className={twMerge(
          mode === "month-view" && "col-span-1",
          mode === "search-results" && "hidden"
        )}
      >
        <p className="font-regular rounded-r-0 rounded-l-md bg-default-100 p-1 text-sm">
          {date}
        </p>
      </div>
      <div
        className={twMerge(
          "flex h-fit w-full flex-col gap-2",
          mode === "month-view" && "col-span-4",
          mode === "search-results" && "col-span-5"
        )}
      >
        {/* <CheckboxGroup isDisabled> */}
        <div>
          {threads?.map((thread, idx) => {
            const participants = createParticipantArray(thread.profiles)
            return (
              <NavigatorTableViewTicketItem
                key={idx}
                setSelectedThread={setSelectedThread}
                selectedThread={selectedThread}
                participants={participants}
                title={thread.title}
                description={thread.description}
                created_at={thread.created_at}
                thread_uid={thread.thread_uid}
              />
            )
          })}
        </div>
        {/* </CheckboxGroup> */}
      </div>
    </div>
  )
}
