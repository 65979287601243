import {GetNavigationThreadsQuery} from "@/gql/graphql"

export interface ProfileBase {
  auth_user_id: string
  channel_type: string
  channel_handle: string
  created_at: string
  is_active: boolean
  name: string
  phone_number: string
}

export interface Profile extends ProfileBase {
  company: string
  title: string
  email: string
}

export interface Message {
  created_at: string
  message: string
  channel_type: string
  provider_handle: string
  provider_type: string
  profiles: Profile[]
}

export interface EmailMessage extends Message {
  attachments: any[]
  raw_content: string
  subject: string
  metadata: EmailMetadata
}

export interface EmailMetadata {
  recipients: {
    type: MessageParticipantType
    email: string
  }[]
}

export enum MessageParticipantType {
  recipient = "recipient",
  cc = "cc",
  bcc = "bcc",
  from = "from",
}

export interface Thread {
  thread_uid: string
  created_at: string
  title: string
  description: string
  last_message_timestamp: string
}

export interface ThreadWithProfiles extends Thread {
  profiles: Profile[]
}

export interface ThreadWithMessages extends ThreadWithProfiles {
  messages: Message[]
}

export interface EmailThread extends ThreadWithProfiles {
  messages: EmailMessage[]
}
