import {useState, useEffect} from "react"
import {useSearchMessages} from "./useSearchMessages"
import {useQuery} from "@apollo/client"
import {SearchedThreadsQuery, SearchedThreadsDocument} from "@/gql/graphql"
import React from "react"
import {Profile, ThreadWithProfiles} from "./shared"

export function useSearchThreads(
  searchTerm?: string,
  dateRanges?: {start: string; end: string}[],
  participantProfileIds?: string[],
  page = 0,
  limit = 100
) {
  const [threads, setThreads] = useState<ThreadWithProfiles[]>([])
  const [searchResults, setSearchResults] = useState<
    {thread_uid: string; message_id: string}[]
  >([])
  const [isFetchingThreads, setIsFetchingThreads] = useState(false)
  const {
    data,
    error,
    isLoading: isSearchLoading,
    hasMore,
  } = useSearchMessages(
    searchTerm,
    dateRanges,
    participantProfileIds,
    page,
    limit
  )

  // We can do a GQL query to get the threads in parallel or after the messages load
  const {client} = useQuery(SearchedThreadsDocument)

  useEffect(() => {
    console.info("📥 Message search results:", {
      messageCount: data?.length,
      hasError: error !== null,
      isLoading: isSearchLoading,
      hasMore,
    })

    if (!data || data.length === 0) {
      console.info("⏭️ Skipping thread fetch - no messages found")
      setThreads([])
      setSearchResults([])
      return
    }

    const fetchThreads = async () => {
      setIsFetchingThreads(true)
      try {
        // Extract the unique thread_uids
        const uniqueThreadIds = [...new Set(data.map((m) => m.thread_uid))]
        console.info("🔍 Fetching threads:", {
          uniqueThreadCount: uniqueThreadIds.length,
          threadIds: uniqueThreadIds,
        })

        // Query GraphQL
        const {data: threadsData} = await client.query({
          query: SearchedThreadsDocument,
          variables: {
            filter: {
              thread_uid: {in: uniqueThreadIds},
            },
          },
        })

        const sanitizedThreads = sanitizeResponse(threadsData)
        console.info("✅ Threads fetched and sanitized:", {
          requestedThreads: uniqueThreadIds.length,
          receivedThreads: sanitizedThreads.length,
        })

        // Sort them in the same order we got from search
        const threadMap = new Map(
          sanitizedThreads.map((thread) => [thread.thread_uid, thread])
        )

        const orderedThreads = uniqueThreadIds
          .map((id) => threadMap.get(id))
          .filter(Boolean) as ThreadWithProfiles[]

        console.info("🏁 Thread processing complete:", {
          finalThreadCount: orderedThreads.length,
          missingThreads: uniqueThreadIds.length - orderedThreads.length,
        })

        setThreads(orderedThreads)
        setSearchResults(data)
      } catch (error) {
        console.error("❌ Error sanitizing threads:", error)
        setThreads([])
      } finally {
        setIsFetchingThreads(false)
      }
    }

    fetchThreads()
  }, [data, client])

  // Update the memoized result to include combined loading state
  const result = React.useMemo(
    () => ({
      threads,
      searchResults,
      error,
      isLoading: isSearchLoading || isFetchingThreads,
      hasMore,
    }),
    [threads, searchResults, error, isSearchLoading, isFetchingThreads, hasMore]
  )

  return result
}

// Add sanitizer function
function sanitizeResponse(
  threadsData: SearchedThreadsQuery
): ThreadWithProfiles[] {
  console.debug("🔍 Sanitizing thread data", {rawData: threadsData})

  const threads = threadsData?.threadsCollection?.edges.map(
    (edge: any) => edge.node
  )
  if (!threads) {
    console.error("❌ Thread data missing")
    throw new Error("No thread data found")
  }

  return threads.map((thread: any) => {
    console.debug("🧵 Thread:", {threadId: thread.thread_uid})

    const profiles =
      thread?.message_groups?.group_participantsCollection?.edges.map(
        (edge: any) => edge.node.profiles
      )
    if (!profiles) {
      console.error("❌ Profiles missing:", thread.thread_uid)
      throw new Error(`No profiles data found on thread ${thread.thread_uid}`)
    }

    return {
      thread_uid: thread.thread_uid,
      created_at: thread.created_at,
      title: thread.title,
      description: thread.description,
      last_message_timestamp: thread.last_message_timestamp,
      profiles: profiles as Profile[],
    }
  })
}
