import {IconCalendar} from "@tabler/icons-react"
import {PullRequestIcon, ChatIcon, LayoutIcon, UsersIcon} from "./Icons"
import {twMerge} from "tailwind-merge"

export type OptionMap =
  | {type: "date_range"; temp_id: string; value: DateRangeFilterOption}
  | {type: "contributors"; temp_id: string; value: ContributorFilterOption}
  | {type: "channel"; temp_id: string; value: any}
  | {type: "discussion"; temp_id: string; value: any}
  | {type: "case"; temp_id: string; value: any}

export type OptionTypes =
  | "date_range"
  | "contributors"
  | "channel"
  | "discussion"
  | "case"

export interface DateRangeFilterOption {
  start: string
  end: string
}

export interface ContributorFilterOption {
  contributors: {
    auth_user_id: string
    full_name: string | null
    profile_ids: string[]
    emails: string[]
    phone_numbers: string[]
  }[]
}

export enum Filters {
  date_range = "Date Range",
  contributors = "Participants",
  channel = "Channel Types",
  discussions = "Discussions",
  cases = "Cases",
}

export enum FilterColors {
  date_range = "bg-success/10 text-success",
  contributors = "bg-warning/10 text-warning",
  channel = "bg-primary/10 text-primary",
  discussions = "bg-secondary/10 text-secondary",
  cases = "bg-default/50 text-foreground",
}

export enum FilterBorderColors {
  date_range = "border-success",
  contributors = "border-warning",
  channel = "border-primary",
  discussions = "border-secondary",
  cases = "border-default",
}

export enum FilterIconColors {
  date_range = "text-success",
  contributors = "text-warning",
  channel = "text-primary",
  discussions = "text-secondary",
  cases = "text-foreground",
}

export const FilterIcons = {
  date_range: <IconCalendar size={16} />,
  contributors: <UsersIcon />,
  channel: <PullRequestIcon />,
  discussions: <ChatIcon />,
  cases: <LayoutIcon />,
}

export const IconWrapper = ({children, className}) => (
  <div
    className={twMerge(
      className,
      "flex h-7 w-7 items-center justify-center rounded-small"
    )}
  >
    {children}
  </div>
)
