import {OptionMap, OptionTypes} from "./utils"
import SelectedFilterItemView from "./SelectedFilterItemView"

const SelectedFiltersListView = ({
  options,
  removeOption,
  changeValue,
}: {
  options: OptionMap[]
  removeOption: (option: OptionMap) => void
  changeValue: (
    option: OptionMap,
    type: OptionTypes,
    value: OptionMap["value"]
  ) => void
}) => {
  //   console.log(options)
  return options.map((option) => {
    return (
      <SelectedFilterItemView
        key={option.temp_id + "filterOptions"}
        optionKey={option.type}
        option={option}
        removeOption={removeOption}
        changeValue={changeValue}
      />
    )
  })
}

export default SelectedFiltersListView
