import React from "react"

import {DotBackground} from "../components/general/ui/DotBackground"
// import partic
import {Button, Image, Input} from "@heroui/react"
import {TSParticle} from "../components/general/ui/TSParticle"
import Logo from "../components/general/Logo"
import {Navigate, useNavigate} from "react-router-dom"
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@heroui/react"

const Hero = ({isMobile}: {isMobile: boolean}) => {
  return (
    <>
      <div className="absolute bottom-6 right-10 flex w-full flex-row items-center justify-center gap-2">
        {/* frequency labs ai privacy policy */}
        <p className="text-mm font-normal text-white">
          Frequency Labs Inc. © 2024 v1.0.0
        </p>
        <p className="text-mm font-normal text-white">|</p>
        {/* <div className="flex flex-row items-center gap-2"> */}
        {/* <p className="text-mm font-normal text-white">Terms & Conditions</p> */}
        <div className="flex flex-row items-center gap-0.5">
          <a
            href="https://app.termly.io/policy-viewer/policy.html?policyUUID=cd106ddf-ec2d-43b8-9fb5-fe17d8d4c288"
            className="text-mm font-normal text-white"
          >
            Privacy Policy
          </a>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="white"
            className=" size-3"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
            />
          </svg>
        </div>

        {/* </div> */}
      </div>
      <div className="flex w-full flex-col gap-4 ">
        <div className="flex w-fit flex-row items-center gap-2 md:gap-4">
          {!isMobile && <Logo type="svg" color="white" size={120} />}
          <div className="relative flex flex-row items-end gap-0 md:gap-2 ">
            <p className="h-[58px] font-monaspaceKrypton text-7xl font-bold leading-none text-onyx-white md:h-20 md:text-8xl">
              QUARTZ
            </p>
            <p className="w-8  pl-2 font-sans text-sm leading-none text-onyx-white md:text-2xl md:leading-none">
              by Obsidian Labs.
            </p>
            <div className="absolute -right-12 -top-1">
              <Logo type="svg" color="white" size={isMobile ? 20 : 0} />
            </div>
          </div>
        </div>
        <p className="self-start font-sans text-xl leading-none text-onyx-white">
          Stay FINRA & SEC compliant with frictionless message archiving across
          iMessage, WhatsApp, and more. No second phone, separate app, or device
          surveillance necessary.
        </p>
        {/* bottom with privacy policy */}
      </div>
    </>
  )
}

function Landing() {
  const navigate = useNavigate()
  let isMobile = false
  if (window.innerWidth < 768) {
    isMobile = true
  }
  const {isOpen, onOpen, onOpenChange} = useDisclosure()
  const [email, setEmail] = React.useState("")
  const [keyboardVisible, setKeyboardVisible] = React.useState(false)

  return (
    <>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, viewport-fit=cover"
      ></meta>
      <Modal
        id="keyboard-modal"
        isOpen={isOpen}
        placement={"bottom-center"}
        onOpenChange={onOpenChange}
        // className=" mb-10"
        className={`mb-10 transition-all ${keyboardVisible ? "-translate-y-72" : ""}`} // Adjust this value based on your needs
        onClose={() => {
          setKeyboardVisible(false)
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Interested? Get in touch!
              </ModalHeader>
              <ModalBody>
                <p className="mb-4">
                  Enter your email to get in touch with us. We will have someone
                  from the Onyx team contact you shortly.
                </p>
                <Input
                  placeholder="Enter your email"
                  type="email"
                  required
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value)
                  }}
                  onTouchStart={() => {
                    setKeyboardVisible(true)
                  }}
                />
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  onPress={() => {
                    onOpenChange()
                    // writeLeadgenDoc(email)
                    setKeyboardVisible(false)
                  }}
                >
                  Send
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
      <div
        className="flex h-dvh w-screen flex-col items-center justify-center  overflow-hidden bg-[#ffffff] dark:bg-gray-900"
        onTouchMove={() => {
          onOpen()
        }}
      >
        <div
          className="absolute bottom-3 left-3 right-3 top-3 z-[0] overflow-hidden  rounded-2xl"
          onDrag={() => {
            console.log("dragging")
          }}
        >
          <div className="absolute bottom-2 left-2 right-2 top-2 z-[0] overflow-clip rounded-xl">
            <DotBackground bg="black" dotColor="white" />
          </div>

          <TSParticle />
        </div>
        <div
          className="z-[0] flex w-full items-center justify-center px-12 md:px-20 lg:px-20"
          onClick={() => {
            console.log("clicked")
          }}
        >
          <Hero isMobile={isMobile} />
        </div>
        <div className="absolute right-12 top-12 z-[0] flex items-center justify-center">
          <Button
            isIconOnly
            style={{height: "fit-content", width: "fit-content"}}
            onClick={() => {
              navigate("/login")
            }}
          >
            <div className="flex flex-row items-center justify-center rounded-xl border border-black/5 bg-white px-4 py-2 font-monaspaceNeon text-xs leading-none">
              <p className="pt-1 font-monaspaceNeon text-xs leading-none">
                LOGIN
              </p>
            </div>
          </Button>
        </div>
      </div>
    </>
  )
}

export default Landing
