import React, {useEffect, useMemo} from "react"
import {Button} from "@heroui/react"
import {
  IconChevronRight,
  IconFilter,
  IconTableExport,
} from "@tabler/icons-react"

import Indicator from "@/components/archive/Indicator"
import FiltersDrawerMenu from "@/components/archive/Filtering/FiltersDrawerMenu"
import {Input} from "@heroui/react"

import {useUser} from "@/contexts/UserContextProvider"
import {DataLoader} from "@/components/general/ui/DataLoader"

import {
  useDisclosure,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@heroui/react"

// import { useGetThreads } from "@/utils/graphQL/queries/useGetThreads"

import MessageView from "@/components/archive/MessageView"
import EmailView from "@/components/archive/EmailView/EmailView"
import {useSearchThreads} from "@/utils/graphQL/queries/threads/useSearchThreads"
import {useDebounce} from "@/hooks/useDebounce"
import {NavigatorTableView} from "@/components/archive/NavigatorTableView"
import {useGetNavigationThreads} from "@/utils/graphQL/queries/threads/useGetNavigationThreads"
import {ThreadWithProfiles} from "@/utils/graphQL/queries/threads/shared"
import {getThreadsByMonthMap} from "@/utils/graphQL/queries/threads/useGetNavigationThreads"

import {useQuery} from "@apollo/client"
import {GetCountOrgUsersDocument} from "@/gql/graphql"
import {twMerge} from "tailwind-merge"
import {exportData} from "@/utils/api/functions/export-data"
import {
  FilterBorderColors,
  FilterIconColors,
  FilterIcons,
  OptionMap,
  OptionTypes,
} from "@/components/archive/Filtering/utils"

const quartzNumber = process.env.REACT_APP_ORG_PHONE_NUMBER
function convertToQuartzFormat(phoneNumber: string) {
  // converts number xxxxxxxxxx to +1 (xxx) xxx-xxxx
  return `+1 (${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`
}

const FilterIndicator = ({
  type,
  label,
  text,
  containerClass,
}: {
  type: OptionTypes
  label?: string
  text?: string
  containerClass?: string
}) => {
  return (
    // </Tooltip>
    <div
      className={twMerge(
        `flex w-fit flex-row items-center gap-1 rounded-full border-2 bg-white p-1 px-2`,
        FilterBorderColors[type],
        containerClass
      )}
    >
      <div
        className={twMerge(
          "flex flex-row items-center gap-2",
          FilterBorderColors[type],
          FilterIconColors[type]
        )}
      >
        {FilterIcons[type]}
      </div>
      <div className="flex flex-row items-center gap-2">
        {label && <p className="text-sm font-medium">{label}</p>}
        {text && <p className="font-regular text-xs">{text}</p>}
      </div>
    </div>
  )
}

function Archive() {
  // use effect
  const [threadLoader, setThreadLoader] = React.useState<boolean>(true)
  const [monthThreads, setMonthThreads] = React.useState<
    Map<string, ThreadWithProfiles[]>
  >(new Map())
  const [selectedThread, setSelectedThread] = React.useState<string | null>(
    null
  )

  const {session, orgId} = useUser()
  const [view, setView] = React.useState<"month-view" | "search-results">(
    "month-view"
  )

  const [searchTerm, setSearchTerm] = React.useState<string>("")
  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  const {data, loading, error} = useGetNavigationThreads()
  const {data: orgUsers} = useQuery(GetCountOrgUsersDocument)
  const numberOfUsers = orgUsers?.org_usersCollection?.edges?.length

  const [exportLoader, setExportLoader] = React.useState<boolean>(false)
  const [filters, setFilters] = React.useState<OptionMap[]>([])

  const {
    isOpen: isExportModalOpen,
    onOpen: onExportModalOpen,
    onOpenChange: onExportModalOpenChange,
  } = useDisclosure()

  useEffect(() => {
    if (loading) return setThreadLoader(true)
    if (error) return
    else {
      if (data) {
        const _monthThreads = getThreadsByMonthMap(data)
        console.log("_monthThreads", _monthThreads)
        setMonthThreads(_monthThreads)
        setThreadLoader(false)
      }
    }
  }, [session, loading, error]) // eslint-disable-line react-hooks/exhaustive-deps

  // Memoize the date range filters
  const dateRanges = useMemo(
    () =>
      filters
        .filter((f) => f.type === "date_range")
        .map((f) => ({
          start: f.value.start,
          end: f.value.end,
        })),
    [filters] // Only recompute when filters change
  )

  // Memoize the contributor profile IDs
  const participantProfileIds = useMemo(() => {
    try {
      return filters
        .filter((f) => f.type === "contributors")
        .flatMap(
          (f) => f.value.contributors?.flatMap((c) => c.profile_ids) || []
        )
    } catch (error) {
      console.error("Error processing contributor filters:", error)
      return []
    }
  }, [filters])

  const {
    isLoading: searchLoading,
    error: searchError,
    threads: searchThreads,
    searchResults,
  } = useSearchThreads(
    debouncedSearchTerm,
    dateRanges,
    participantProfileIds,
    0
  )

  // useEffect(() => {
  //   if (searchThreads.length > 0) {
  //     setView("search-results")
  //   } else {
  //     setView("month-view")
  //   }
  // }, [searchThreads])

  useEffect(() => {
    if (searchTerm.length > 0 || filters.length > 0) {
      setView("search-results")
    } else {
      setView("month-view")
    }
  }, [searchTerm, filters])

  function selectThread(thread_uid: string) {
    setSelectedThread(thread_uid)
  }
  const {
    isOpen: isFilterDrawerOpen,
    onOpen: onFilterDrawerOpen,
    onOpenChange: onFilterDrawerOpenChange,
  } = useDisclosure()

  function submitFilters(filters: any) {
    setFilters(filters)
  }

  async function exportOrganizationData() {
    setExportLoader(true)
    if (!orgId) {
      console.error("No orgId found")
      setExportLoader(false)
      return
    }
    await exportData({orgId: orgId})
    setExportLoader(false)
  }

  return (
    <div className="animate-fade animate-once animate-ease-linear mr-4 flex w-full grid-cols-7 justify-between gap-4 rounded-md border-black/5 bg-onyx-white p-4  transition-all duration-150">
      <div className=" col-span-4 flex h-full w-full min-w-[30%] flex-col justify-between overflow-scroll bg-white scrollbar-hide">
        <div className="sticky top-0 z-50 flex flex-col justify-start gap-2 bg-white">
          <div className="z-50 flex w-full flex-row items-center justify-between gap-2 bg-white">
            <div className="flex flex-col gap-2 bg-white">
              {/* <Indicator
                isProcessed={true}
                type="status"
                label="Dedicated Quartz Number"
                text={convertToQuartzFormat(quartzNumber)}
              /> */}
              <Indicator
                isProcessed={true}
                type="status"
                label={`${numberOfUsers} Whitelisted Users`}
              />
            </div>
            <Button
              onPress={onExportModalOpen}
              isIconOnly
              isLoading={exportLoader}
              isDisabled={exportLoader}
            >
              <div className="flex flex-row items-center gap-2">
                <IconTableExport className="h-4 w-4" />
              </div>
            </Button>
          </div>
          <div className="z-20 flex h-8 flex-row justify-end">
            <Button
              className=""
              variant="bordered"
              color="primary"
              size="sm"
              radius="full"
              onPress={onFilterDrawerOpen}
            >
              <IconFilter className="h-4 w-4" />
              <p>All</p>
            </Button>
            {filters.length > 0 && (
              <div className="ml-2 flex flex-row gap-0">
                {Array.from(
                  filters.reduce((acc, filter) => {
                    acc.set(filter.type, (acc.get(filter.type) || 0) + 1)
                    return acc
                  }, new Map<string, number>())
                ).map(([type, count]: [string, number], index: number) => {
                  return (
                    <FilterIndicator
                      type={type as OptionTypes}
                      // label={type}
                      text={String(count)}
                      containerClass={index > 0 ? "translate-x-[-0.5rem]" : ""}
                    />
                  )
                })}
              </div>
            )}
            <FiltersDrawerMenu
              isOpen={isFilterDrawerOpen}
              onOpenChange={onFilterDrawerOpenChange}
              submitFilters={submitFilters}
            />
          </div>

          <div className="w-full">
            <Input
              classNames={{
                base: "w-full",
                inputWrapper: "border-2 border-blue-500",
              }}
              placeholder="Search"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <p className="font-regular mb-2 ml-[0.375rem] mt-[0.125rem] select-none text-[8px]">
              Search by name, phone number, email, or type a prompt and Quartz
              will find examples of it (i.e. "Report of occurances where
              converations clearly are missing context signalling conversation
              off channel")
            </p>
          </div>
        </div>
        <div className="z-10 min-h-[80%]">
          {threadLoader && <DataLoader />}
          {view === "month-view" && monthThreads && monthThreads.size > 0 ? (
            Array.from(monthThreads.entries()).map(([date, thread], idx) => {
              return (
                <NavigatorTableView
                  key={idx}
                  threads={thread}
                  date={date}
                  setSelectedThread={selectThread}
                  selectedThread={selectedThread}
                  mode={view}
                />
              )
            })
          ) : view === "search-results" ? (
            searchLoading || searchTerm !== debouncedSearchTerm ? (
              <DataLoader />
            ) : searchThreads && searchThreads.length > 0 ? (
              <NavigatorTableView
                key={"search-results"}
                threads={searchThreads}
                date={"Search Results"}
                setSelectedThread={selectThread}
                selectedThread={selectedThread}
                mode={view}
              />
            ) : (
              <div className="p-4">
                <p className="text-default-500">No matching threads found</p>
              </div>
            )
          ) : (
            <p>No threads</p>
          )}
        </div>
      </div>
      <EmailView
        selectedThread={selectedThread}
        setSelectedThread={setSelectedThread}
      />

      <Modal isOpen={isExportModalOpen} onOpenChange={onExportModalOpenChange}>
        <ModalContent>
          <ModalHeader>Export Organization Data</ModalHeader>
          <ModalBody>
            Are you sure you want to export all your organization data? This
            might take a few minutes depending on the amount of data in your
            organization. The download will start automatically, please don't
            refresh the page.
          </ModalBody>
          <ModalFooter>
            <Button variant="light" onPress={onExportModalOpenChange}>
              Cancel
            </Button>
            <Button
              color="primary"
              onPress={() => {
                exportOrganizationData()
                onExportModalOpenChange()
              }}
            >
              Export
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  )
}

export default Archive
