import {useEffect, useState} from "react"
import {useUser} from "@/contexts/UserContextProvider"
import AuthError from "@/components/auth/AuthError"
import {useNavigate} from "react-router-dom"
import {useGetMyUserDashboard} from "../graphQL/queries/userDashboard/useGetMyUserDashboard"

const AuthenticatedRoute = ({children}: {children: React.ReactNode}) => {
  const {session} = useUser()
  const navigate = useNavigate()
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false)
  const [isAdmin, setIsAdmin] = useState<boolean>(false)
  const [isOnboarded, setIsOnboarded] = useState<boolean>(false)

  const {data, loading, error, refetch} = useGetMyUserDashboard(
    session?.user?.id
  )

  console.info("🔍 [Auth] Data:", data)

  function setAllStatesToFalse() {
    setIsAuthorized(false)
    setIsAdmin(false)
    setIsOnboarded(false)
  }

  useEffect(() => {
    console.info("🔍 [Auth] State:", {
      hasSession: !!session,
      loading,
      hasError: !!error,
    })

    if (loading || error || !session || !data) {
      if (error) {
        console.info("❌ [Auth] Load failed:", error)
      }
      setAllStatesToFalse()
      return
    }

    console.info("✅ [Auth] Load successful")
    setIsAuthorized(true)

    if (data?.isAdmin) {
      setIsAdmin(true)
      console.info("✅ [Auth] Admin access granted")
    }

    console.info("🔍 [Auth] Onboarding Check:", {
      availableProviders: data?.availableProviders,
      integrations: data?.integrations,
      onboardingResult: Object.values(data?.integrations ?? {}).some(
        (integration) => integration?.status === "connected"
      ),
    })

    setIsOnboarded(
      Object.values(data?.integrations ?? {}).some(
        (integration) => integration?.status === "connected"
      )
    )
  }, [session, data, loading, error])

  if (loading || isAuthorized === null) {
    return (
      <div className="flex h-screen flex-col items-center justify-center">
        Loading...
      </div>
    )
  }

  if (!isAuthorized) {
    return <AuthError type="not_logged_in" pageContainer={true} />
  }

  if (!isOnboarded) {
    navigate("/auth/onboarding/configure_integrations")
    return null
  }

  if (!isAdmin) {
    return <AuthError type="not_admin" pageContainer={true} />
  }

  return children
}

export default AuthenticatedRoute
