import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  defaultDataIdFromObject,
} from "@apollo/client"
import {setContext} from "@apollo/client/link/context"
import supabase from "@/supabaseConfig"

const GRAPHQL_API_URL = process.env.REACT_APP_GRAPHQL_API_URL

const cache = new InMemoryCache({
  dataIdFromObject(responseObject) {
    if ("nodeId" in responseObject) {
      return `${responseObject.nodeId}`
    }
    return defaultDataIdFromObject(responseObject)
  },
  typePolicies: {
    Query: {
      fields: {
        node: {
          read(_, {args, toReference}) {
            const ref = toReference({
              nodeId: args?.nodeId,
            })
            return ref
          },
        },
      },
    },
  },
})

const httpLink = createHttpLink({
  uri: GRAPHQL_API_URL,
})

const authLink = setContext(async (_, {headers}) => {
  const {
    data: {session},
  } = await supabase.auth.getSession()
  const token = session?.access_token || ""

  return {
    headers: {
      ...headers,
      apiKey: process.env.REACT_APP_SUPABASE_ANON_KEY,
      authorization: token ? `Bearer ${token}` : "",
    },
  }
})

// Create a function to get a new client instance
export function getApolloClient() {
  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
      dataIdFromObject(responseObject) {
        if ("nodeId" in responseObject) {
          return `${responseObject.nodeId}`
        }
        return defaultDataIdFromObject(responseObject)
      },
      typePolicies: {
        Query: {
          fields: {
            node: {
              read(_, {args, toReference}) {
                const ref = toReference({
                  nodeId: args?.nodeId,
                })
                return ref
              },
            },
          },
        },
      },
    }),
  })
}

// Export a default client instance
export const client = getApolloClient()
