import {
  EmailMessage,
  MessageParticipantType,
} from "@/utils/graphQL/queries/threads/shared"
import MessageTableItemView from "./MessageTableItemView"

const MessageTableView = ({messages}: {messages: EmailMessage[]}) => {
  return (
    <div className="flex h-full w-full flex-col gap-2 bg-onyx-slate/15 bg-grid-small-onyx-black/15">
      {messages &&
        messages.map((message, idx) => {
          const metadataProvided = message.metadata

          const sender = metadataProvided
            ? message.metadata.recipients
                .filter(
                  (recipient) => recipient.type === MessageParticipantType.from
                )
                .map((recipient) => recipient.email)
            : ["unknown"]
          const receiver = metadataProvided
            ? message.metadata.recipients
                .filter(
                  (recipient) =>
                    recipient.type === MessageParticipantType.recipient
                )
                .map((recipient) => recipient.email)
            : ["unknown"]
          const cc = metadataProvided
            ? message.metadata.recipients
                .filter(
                  (recipient) => recipient.type === MessageParticipantType.cc
                )
                .map((recipient) => recipient.email)
            : ["unknown"]
          const bcc = metadataProvided
            ? message.metadata.recipients
                .filter(
                  (recipient) => recipient.type === MessageParticipantType.bcc
                )
                .map((recipient) => recipient.email)
            : ["unknown"]

          return (
            <MessageTableItemView
              sender={sender}
              receiver={receiver}
              subject={message.subject}
              message={message.message}
              raw_content={message.raw_content}
              createdAt={message.created_at}
              cc={cc}
              bcc={bcc}
              idx={idx}
            />
          )
        })}
    </div>
  )
}

export default MessageTableView
