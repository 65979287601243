import {twMerge} from "tailwind-merge"
import {
  IconCircleDashedCheck,
  IconCircleDashedX,
  IconProgressAlert,
  IconProgressCheck,
} from "@tabler/icons-react"

const Indicator = ({
  isProcessed,
  type,
  label,
  text,
  containerClass,
}: {
  isProcessed: boolean
  type: "status" | "processing" | "error"
  label?: string
  text?: string
  containerClass?: string
}) => {
  const color = isProcessed ? "bg-green-500" : "bg-red-500"
  return (
    <div
      className={twMerge(
        `flex w-fit flex-row items-center gap-1 rounded-xl border border-black/5 bg-white p-1`,
        containerClass
      )}
    >
      {/* <div className={`h-2 w-2  rounded-full ${color}`}></div> */}
      {type === "status" ? (
        isProcessed ? (
          <IconCircleDashedCheck className="h-4 w-4" color="green" />
        ) : (
          <IconCircleDashedX className="h-4 w-4" color="red" />
        )
      ) : type === "error" ? (
        <IconCircleDashedX className="h-4 w-4" color="red" />
      ) : isProcessed ? (
        <IconProgressCheck className="h-4 w-4" color="green" />
      ) : (
        <IconProgressAlert className="h-4 w-4" color="orange" />
      )}
      <div className="flex flex-row items-center gap-2">
        {label && <p className="text-sm font-medium">{label}</p>}
        {text && <p className="font-regular text-xs">{text}</p>}
      </div>
    </div>
    // </Tooltip>
  )
}

export default Indicator
