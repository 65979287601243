import supabase from "@/supabaseConfig"

/**
 * Search messages by optional text-search, participant filters, and date ranges.
 */
export async function searchMessages({
  searchTerm,
  dateRanges,
  participantProfileIds,
  page = 0,
  limit = 100,
}: {
  searchTerm?: string
  dateRanges?: {start: string; end: string}[]
  participantProfileIds?: string[]
  page?: number
  limit?: number
}): Promise<{
  data: {thread_uid: string; message_id: string}[] | null
  error: Error | null
  count: number | null
  hasMore: boolean
}> {
  console.info("🔎 Starting message search:", {
    searchTerm,
    dateRanges,
    participantProfileIds,
    page,
    limit,
    offset: page * limit,
  })

  const offset = page * limit

  // -- Optional Step (A): If filtering by participants, find all group_ids that match:
  let filteredGroupIds: string[] | undefined
  if (participantProfileIds && participantProfileIds.length > 0) {
    console.info(
      "👥 Fetching group IDs for participants:",
      participantProfileIds
    )

    const {data: groupParticipantsData, error: gpError} = await supabase
      .from("group_participants")
      .select("group_id")
      .in("profile_id", participantProfileIds)
    // TODO: Find a way to implement this in a single query so it scales
    // Large numbers of group participants will cause the query to timeout

    if (gpError) {
      console.error("❌ Error fetching group participants:", gpError)
      return {
        data: null,
        error: gpError,
        count: null,
        hasMore: false,
      }
    }

    if (!groupParticipantsData || groupParticipantsData.length === 0) {
      console.info("⚠️ No matching groups found for participants")
      return {
        data: [],
        error: null,
        count: 0,
        hasMore: false,
      }
    }

    filteredGroupIds = groupParticipantsData.map((g) => g.group_id)
    console.info("✅ Found matching groups:", {
      count: filteredGroupIds.length,
      groupIds: filteredGroupIds,
    })
  }

  // -- Step (B): Build the query
  console.info("🏗️ Building messages query...")
  let query = supabase.from("messages").select(
    `
      message_id,
      thread_messages!inner(thread_uid)
    `,
    {count: "exact"}
  )

  // Optional: text search
  if (searchTerm) {
    const formattedSearch = searchTerm
      .trim()
      .split(/\s+/)
      .map((term) => term + ":*")
      .join(" & ")
    console.info("🔤 Adding text search:", {
      original: searchTerm,
      formatted: formattedSearch,
    })
    query = query.textSearch("raw_content", formattedSearch, {
      type: "websearch",
      config: "english",
    })
  }

  // Optional: participant group_ids
  if (filteredGroupIds) {
    console.info("👥 Adding group filter:", filteredGroupIds)
    query = query.in("group_id", filteredGroupIds)
  }

  // Optional: date ranges
  if (dateRanges && dateRanges.length > 0) {
    const orFilters = dateRanges
      .map((dr) => {
        // Append time so that JavaScript parses them as local datetime strings.
        const startDate = new Date(`${dr.start}T00:00:00`)
        const endDate = new Date(`${dr.end}T23:59:59.999`)

        // .toISOString() gives the proper UTC value for the query.
        return `and(created_at.gte.${startDate.toISOString()},created_at.lte.${endDate.toISOString()})`
      })
      .join(",")

    console.info("📅 Adding date filters:", {dateRanges, orFilters})
    query = query.or(orFilters)
  }

  // Pagination
  query = query.range(offset, offset + limit - 1)

  // Execute
  console.info("🚀 Executing query...")
  const {data, error, count} = await query

  if (error) {
    console.error("❌ Query error:", error)
    return {data: null, error, count: null, hasMore: false}
  }

  // Transform
  const transformedData =
    data?.map((item) => {
      const threadUid = item.thread_messages?.[0]?.thread_uid
      return {
        message_id: item.message_id,
        thread_uid: threadUid,
      }
    }) || []

  const hasMore = !!count && offset + limit < count

  console.info("✅ Search complete:", {
    resultsCount: transformedData.length,
    totalCount: count,
    hasMore,
    page,
    limit,
  })

  return {
    data: transformedData,
    error: null,
    count: count ?? 0,
    hasMore,
  }
}
