import {Tooltip} from "@heroui/react"
import {IconLock} from "@tabler/icons-react"

export const LockedFeatureIndicator = () => {
  return (
    <Tooltip content="This feature is for trial sandbox users">
      <IconLock className="h-4 w-4 rounded-full bg-neutral-300 p-[0.125rem] text-white" />
    </Tooltip>
  )
}
