import {useState} from "react"
import {convertDate, convertTimestamp, getDuration} from "../utils"
import {twMerge} from "tailwind-merge"
import {LockedFeatureIndicator} from "@/components/general/ui/LockedFeatureIndicator"

const EmailThreadSummary = ({thread_uid}: {thread_uid: string}) => {
  const [open, setOpen] = useState(false)
  return (
    <div className="flex flex-col items-start gap-1">
      <div
        className="flex w-fit cursor-pointer flex-row items-center gap-1 rounded-md border border-black/5 bg-white px-1 py-1 text-xs font-medium text-neutral-400"
        onClick={() => setOpen(!open)}
      >
        <LockedFeatureIndicator />
        Summary
      </div>

      <div
        className={twMerge(
          "ml-1 mt-1 flex max-h-0 flex-col gap-1 overflow-hidden text-xs transition-all duration-150",
          open ? "h-fit max-h-10" : "h-0"
        )}
      >
        <p>Summaries for this thread are not available with your demo plan</p>
      </div>
    </div>
  )
}

export default EmailThreadSummary
