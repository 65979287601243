import {DateRangePicker, Listbox, ListboxItem, cn} from "@heroui/react"
import {IconWrapper} from "./utils"
import React from "react"

import {
  FilterColors,
  FilterIcons,
  Filters,
  OptionMap,
  OptionTypes,
} from "./utils"
import {IconLockFilled, IconPlus} from "@tabler/icons-react"

export const ItemCounter = ({number}) => (
  <div className="flex items-center gap-1 text-default-400">
    <span className="text-xs">{number}</span>
    <IconPlus size={16} className="rounded-full border-2 text-default-400" />
  </div>
)

export default function FiltersList({
  options,
  onAdd,
}: {
  options: OptionMap[]
  onAdd: (option: OptionTypes) => void
}) {
  return (
    <Listbox
      aria-label="Filters Menu"
      className="gap-0 divide-y divide-default-300/50 overflow-visible rounded-medium bg-content1 p-0 shadow-small dark:divide-default-100/80"
      itemClasses={{
        base: "px-3 first:rounded-t-medium last:rounded-b-medium rounded-none gap-3 h-12 data-[hover=true]:bg-default-100/80",
      }}
      onAction={(key) => onAdd(key as OptionTypes)}
    >
      {/* create one for each in optiontypes */}
      {Object.entries(Filters).map(([option]) => {
        return (
          <ListboxItem
            key={option}
            className="group h-auto py-3"
            endContent={
              option === "contributors" || option === "date_range" ? (
                <ItemCounter
                  number={options.filter((o) => o.type === option).length}
                />
              ) : null
            }
            startContent={
              <IconWrapper
                className={
                  option === "contributors" || option === "date_range"
                    ? FilterColors[option]
                    : "bg-default/50 text-foreground"
                }
              >
                {option === "date_range" || option === "contributors" ? (
                  FilterIcons[option]
                ) : (
                  <IconLockFilled size={16} />
                )}
              </IconWrapper>
            }
            textValue={Filters[option]}
          >
            <div className="group flex flex-col gap-1 transition-all duration-300">
              <p>{Filters[option]}</p>
            </div>
          </ListboxItem>
        )
      })}
    </Listbox>
  )
}
