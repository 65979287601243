import {useQuery} from "@apollo/client"
import React from "react"
import {
  GetMyUserDashboardDocument,
  GetMyUserDashboardQuery,
} from "@/gql/graphql"
import {DashboardResponse} from "./shared"
import {sanitizeDashboardResponse} from "./shared"

// Converts the raw query response into our desired shape.
function sanitizeResponse(data: GetMyUserDashboardQuery): DashboardResponse {
  return sanitizeDashboardResponse(
    data?.user_dashboardCollection?.edges[0]?.node
  )
}

export function useGetMyUserDashboard(userId: string | null) {
  if (!userId) {
    console.debug("👻 [Dashboard] No user ID provided, query skipped")
  }

  const result = useQuery<GetMyUserDashboardQuery>(GetMyUserDashboardDocument, {
    variables: {auth_user_id: userId},
    skip: !userId,
    onError: (error) => {
      console.info("💥 [Dashboard] Query failed:", error.message)
    },
    onCompleted: (data) => {
      console.debug("📊 [Dashboard] Data loaded:", {
        hasData: !!data,
        userFound: !!data?.user_dashboardCollection?.edges[0],
      })
    },
  })

  // Memoize the sanitized data so it's only recalculated when result.data changes.
  const sanitizedData = React.useMemo(() => {
    return result.data ? sanitizeResponse(result.data) : undefined
  }, [result.data])

  console.debug("🧹 [Dashboard] Sanitized data:", {
    isAdmin: sanitizedData?.isAdmin,
    hasIntegrations: !!sanitizedData?.integrations,
    integrationCount: sanitizedData?.integrations
      ? Object.keys(sanitizedData.integrations).length
      : 0,
  })

  // If problems are still persisting, you can wrap the whole object in a useMemo
  // wrap it in a useMemo as well.
  // const stableResult = React.useMemo(
  //   () => ({
  //     ...result,
  //     data: sanitizedData,
  //   }),
  //   [result.loading, result.error, result.networkStatus, sanitizedData]
  // )

  return {
    ...result,
    data: sanitizedData,
  }
}
