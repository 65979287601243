import {GetEmailThreadDocument, GetEmailThreadQuery} from "@/gql/graphql"
import {EmailThread, EmailMessage, Profile} from "./shared"
import {useQuery} from "@apollo/client"
import React from "react"

// Transform raw GraphQL data into our desired thread shape.
function sanitizeResponse(data: GetEmailThreadQuery): EmailThread {
  console.debug("🔍 Sanitizing thread data", {rawData: data})

  const thread = data.threadsCollection?.edges[0]?.node
  // if (!thread) {
  //   console.error("❌ Thread data missing")
  //   throw new Error("No thread data found")
  // }
  // if (!thread.message_groups) throw new Error("No message groups found")
  // if (!thread.thread_messagesCollection)
  //   throw new Error("No thread messages found")
  // if (!thread.title) throw new Error("No title found")
  // if (!thread.description) throw new Error("No description found")
  // if (!thread.last_message_timestamp)
  //   throw new Error("No last message timestamp found")
  // if (!thread.created_at) throw new Error("No created at found")
  // if (!thread.thread_uid) throw new Error("No thread uid found")

  const profiles =
    thread.message_groups?.group_participantsCollection?.edges.map(
      (edge) => edge.node.profiles
    )

  const messages = thread.thread_messagesCollection?.edges.map((edge) => {
    const metadata = edge.node.messages?.metadata
      ? JSON.parse(edge.node.messages?.metadata)
      : {}
    return {
      ...edge.node.messages,
      profiles: profiles as Profile[],
      metadata: metadata,
      attachments: edge.node.messages?.attachmentsCollection?.edges.map(
        (edge) => edge.node
      ),
    }
  })

  return {
    thread_uid: thread.thread_uid,
    created_at: thread.created_at,
    title: thread.title,
    description: thread.description,
    last_message_timestamp: thread.last_message_timestamp,
    profiles: profiles as Profile[],
    messages: messages as EmailMessage[],
  }
}

// Final hook.
export function useGetEmailThread(thread_uid: string | null) {
  if (!thread_uid) {
    console.debug("👻 [EmailThread] No thread UID provided, query skipped")
  }

  const result = useQuery<GetEmailThreadQuery>(GetEmailThreadDocument, {
    variables: {
      filter: {
        thread_uid: {
          eq: thread_uid,
        },
      },
      orderBy: [
        {
          created_at: "AscNullsLast",
        },
      ],
    },
    skip: !thread_uid,
    onError: (error) => {
      console.error("❌ Query failed:", error.message)
    },
    onCompleted: (data) => {
      console.info("✅ Query completed:", {
        hasData: !!data,
      })
    },
  })

  // Combine sanitization and month organization into a single memoized operation
  const {
    sanitizedData,
    title,
    description,
    lastTimestamp,
    createdAt,
    threadParticipants,
    messages,
    subject,
    provider,
  } = React.useMemo(() => {
    console.debug("🔄 Processing data:", {
      hasData: !!result.data,
    })

    if (!result.data) {
      return {
        sanitizedData: undefined,
      }
    }

    const processed = sanitizeResponse(result.data)
    console.debug("🔍 Sanitized thread data", {
      sanitizedData: processed,
    })

    const subject = processed.messages[processed.messages.length - 1]?.subject
    const provider =
      processed.messages[processed.messages.length - 1]?.provider_type.split(
        "/"
      )[1]

    return {
      sanitizedData: processed,
      title: processed.title,
      description: processed.description,
      lastTimestamp: processed.last_message_timestamp,
      createdAt: processed.created_at,
      threadParticipants: processed.profiles,
      messages: processed.messages,
      subject,
      provider,
    }
  }, [result.data])

  return {
    ...result,
    data: sanitizedData,
    title,
    description,
    lastTimestamp,
    createdAt,
    threadParticipants,
    messages,
    subject,
    provider,
  }
}
