import React from "react"
import {twMerge} from "tailwind-merge"
import {IconMail} from "@tabler/icons-react"
import ChannelIndicator from "./ChannelIndicator"

interface NavigatorTableViewTicketItemProps {
  setSelectedThread: (thread_uid: string) => void
  selectedThread: string
  participants: string[]
  title: string
  description: string
  created_at: string
  thread_uid: string
}

const NavigatorTableViewTicketItem = ({
  setSelectedThread,
  selectedThread,
  participants,
  title,
  description,
  created_at,
  thread_uid,
}: NavigatorTableViewTicketItemProps) => {
  const [selected, setSelected] = React.useState<boolean>(false)
  React.useEffect(() => {
    if (selectedThread === thread_uid) {
      setSelected(true)
    } else {
      setSelected(false)
    }
  }, [selectedThread, thread_uid])

  return (
    // <Checkbox
    //   className="relative w-full "
    //   classNames={{
    //     base: "w-full max-w-full m-0",
    //     label: "w-full max-w-full",
    //   }}
    //   onClick={() => setSelectedMessage(message)}
    // > // turn this on when we actually have something we need a checkbox for
    <div
      className={twMerge(
        "group relative cursor-pointer border-b border-l border-black/5  px-2 py-4 hover:bg-onyx-slate",
        selected ? "bg-onyx-slate px-4" : ""
      )}
      onClick={() => {
        setSelectedThread(thread_uid)
      }}
    >
      <div className="flex flex-col">
        <ChannelIndicator
          channel_type="email"
          text={title}
          size="sm"
          containerClass="mb-1"
        />
        <p className="text-sm font-medium">
          {participants.length === 2
            ? participants.join(" & ")
            : participants.join(" , ")}
        </p>
        <p className="font-regular h-0 overflow-hidden text-xs group-hover:h-fit">
          {description ||
            "Conversation Summaries are not available with your demo."}
        </p>
      </div>
      <div className="absolute right-0 top-0 z-10">
        <p className="font-regular p-1 text-xs">
          {new Date(created_at).toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
          })}
        </p>
      </div>
    </div>
    // </Checkbox>
  )
}

export default NavigatorTableViewTicketItem
