// import {useQuery, useQueryClient} from "@tanstack/react-query"
import {useMutation, useQuery, gql} from "@apollo/client"

import React, {useState, useEffect} from "react"

import {twMerge} from "tailwind-merge"
import {
  DashboardResponse,
  sanitizeDashboardResponse,
} from "@/utils/graphQL/queries/userDashboard/shared"
import {
  IconCircleDashed,
  IconCircleDashedLetterX,
  IconPlus,
  IconProgressAlert,
  IconProgressCheck,
  IconRefresh,
  IconTrash,
} from "@tabler/icons-react"
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Tooltip,
  useDisclosure,
} from "@heroui/react"
import {inviteUser} from "@/utils/api/functions/invite-user"
import {removeUser} from "@/utils/api/functions/remove-user"
import {useUser} from "@/contexts/UserContextProvider"
import {useGetOrgUsersDashboard} from "@/utils/graphQL/queries/userDashboard/useGetOrgUsersDashboard"
// import {graphql} from "./gql/gql"

// const GET_THREADS = graphql(/* GraphQL */ `
//   query GetThreads {
//     threadsCollection {
//       edges {
//         node {
//           thread_messagesCollection {
//             edges {
//               node {
//                 messages {
//                   created_at
//                   message
//                 }
//                 created_at
//               }
//             }
//           }
//           last_message_timestamp
//           created_at
//         }
//       }
//     }
//   }
// `)

// export const GET_THREADS = gql`
//   // insert query here
// `

const Indicator = ({
  isProcessed,
  type,
  label,
  text,
  containerClass,
  loader,
}: {
  isProcessed: boolean
  type: "status" | "processing" | "error"
  label?: string
  text?: string
  containerClass?: string
  loader?: boolean
}) => {
  const color = isProcessed ? "bg-green-500" : "bg-red-500"
  return (
    // </Tooltip>
    <div
      className={twMerge(
        `flex w-fit flex-row items-center gap-1 rounded-xl border border-black/5 bg-white p-1`,
        containerClass
      )}
    >
      {/* <div className={`h-2 w-2  rounded-full ${color}`}></div> */}
      {type === "status" ? (
        isProcessed ? (
          <IconCircleDashed
            className={twMerge("h-4 w-4", loader ? "animate-spin" : "")}
          />
        ) : (
          <IconCircleDashedLetterX className="h-4 w-4" color="red" />
        )
      ) : type === "error" ? (
        <IconCircleDashedLetterX className="h-4 w-4" color="red" />
      ) : isProcessed ? (
        <IconProgressCheck className="h-4 w-4" color="green" />
      ) : (
        <IconProgressAlert className="h-4 w-4" color="orange" />
      )}
      <div className="flex flex-row items-center gap-2">
        {label && <p className="text-sm font-medium">{label}</p>}
        {text && <p className="font-regular text-xs">{text}</p>}
      </div>
    </div>
  )
}

const UserItem = ({
  user,
  session,
  setDataLoader,
  refetch,
}: {
  user: DashboardResponse
  session: any
  setDataLoader: React.Dispatch<React.SetStateAction<boolean>>
  refetch: () => void
}) => {
  const [loading, setLoading] = useState(false)
  const {isOpen, onOpen, onOpenChange} = useDisclosure()

  const handleRemoveUser = async () => {
    try {
      setLoading(true)
      await removeUser({
        authUserToRemove: user?.authUserId,
        session,
      })
      setDataLoader((prev) => !prev)
      await refetch()
    } catch (error) {
      console.error("Failed to remove user:", error)
    } finally {
      setLoading(false)
    }
  }

  const hasOutlookIntegration =
    user.integrations?.["unified/outlook"]?.is_active || false
  const inviteStatus = user.inviteAccepted

  return (
    <>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>Confirm User Removal</ModalHeader>
              <ModalBody>
                Are you sure you want to remove {user?.fullName}? This action
                cannot be undone.
              </ModalBody>
              <ModalFooter>
                <Button color="default" variant="light" onPress={onClose}>
                  Cancel
                </Button>
                <Button
                  color="danger"
                  onPress={() => {
                    handleRemoveUser()
                    onClose()
                  }}
                  isLoading={loading}
                >
                  Remove User
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

      <div className="flex animate-appearance-in flex-row items-center justify-between gap-2 rounded-xl border border-black/5 bg-white p-2">
        <div className="flex flex-row items-center gap-4">
          <div className="flex flex-col">
            <p className="text-sm font-medium">{user?.fullName}</p>
            <p className="text-xs text-gray-500">{user?.email}</p>
          </div>
          <div className="flex h-full items-center">
            <span className="rounded-full bg-gray-100 px-2 py-0.5 text-xs font-medium text-gray-600">
              {user?.role}
            </span>
          </div>
        </div>
        <div className="flex flex-row items-center gap-2">
          <div className="flex gap-2">
            <Indicator
              isProcessed={inviteStatus}
              type="processing"
              text={inviteStatus ? "Invite Accepted" : "Pending Invite"}
            />
            <Indicator
              isProcessed={hasOutlookIntegration}
              type={hasOutlookIntegration ? "processing" : "error"}
              text={
                hasOutlookIntegration
                  ? "Outlook Connected"
                  : "No Outlook Connection"
              }
            />
          </div>
          <Tooltip content="Delete User" placement="bottom-end">
            <Button
              variant="flat"
              color="danger"
              isIconOnly
              radius="full"
              startContent={<IconTrash size={16} />}
              onClick={onOpen}
              isLoading={loading}
            />
          </Tooltip>
          {!inviteStatus && (
            <Tooltip content="Resend Invite" placement="bottom-end">
              <Button
                variant="flat"
                color="primary"
                isIconOnly
                radius="full"
                onClick={() =>
                  inviteUser({
                    userName: user?.fullName,
                    userEmail: user?.email,
                    session,
                  })
                }
                startContent={<IconRefresh size={16} />}
              ></Button>
            </Tooltip>
          )}
        </div>
      </div>
    </>
  )
}

export const User = () => {
  // get current user from supabase session
  const {user, session} = useUser()

  const [dataLoader, setDataLoader] = useState(false)
  const [inviteSubmitting, setInviteSubmitting] = useState(false)

  const {data: orgUsers, loading, error, refetch} = useGetOrgUsersDashboard()

  useEffect(() => {
    console.log("DATA CHANGED?", orgUsers)
  }, [orgUsers])

  const {isOpen, onOpen, onOpenChange} = useDisclosure()

  const handleSubmit = async (userName: string, userEmail: string) => {
    console.info("submitting invite for ", userName, userEmail)
    setInviteSubmitting(true)
    await inviteUser({userName, userEmail, session})
    setUserName("")
    setUserEmail("")
    setDataLoader(!dataLoader)
    setInviteSubmitting(false)
    // onClose()
    refetch()
  }

  const [userName, setUserName] = useState("")
  const [userEmail, setUserEmail] = useState("")

  return (
    <div className="relative flex h-full w-full flex-col p-4">
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        backdrop="transparent"
        size="sm"
        classNames={{
          base: "absolute right-0 top-6",
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                <p className="text-lg font-medium">Invite New User</p>
                <p className="text-sm font-normal">
                  Add a new user to your organization by entering their name and
                  email address.
                </p>
              </ModalHeader>
              <ModalBody>
                <Input
                  label="Full Name"
                  value={userName}
                  isRequired
                  onChange={(e) => setUserName(e.target.value)}
                />
                <Input
                  label="Email"
                  value={userEmail}
                  type="email"
                  isRequired
                  isInvalid={!userEmail.includes("@") && userEmail !== ""}
                  onChange={(e) => setUserEmail(e.target.value)}
                />
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  size="sm"
                  isDisabled={!userName || !userEmail}
                  onPress={() =>
                    handleSubmit(userName, userEmail).then(() => onClose())
                  }
                  isLoading={loading}
                >
                  Add User
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-col gap-2">
          <p className="text-lg font-medium">Linked Users</p>
          <Indicator
            isProcessed={true}
            type="status"
            label={`${orgUsers?.length} Whitelisted Users`}
            loader={orgUsers?.length === 0}
          />
        </div>
        <Tooltip content="Add User" placement="bottom-end">
          <Button
            variant="flat"
            color="primary"
            isIconOnly
            radius="full"
            startContent={<IconPlus size={16} />}
            className="max-h-fit w-fit min-w-fit max-w-fit p-2"
            onClick={onOpen}
          ></Button>
        </Tooltip>
      </div>
      {/* <p>{JSON.stringify(orgUsers)}</p> */}
      <div className="mt-2 flex flex-col gap-2">
        {orgUsers?.map((user) => (
          <UserItem
            key={user.authUserId}
            user={user}
            session={session}
            setDataLoader={setDataLoader}
            refetch={refetch}
          />
        ))}
      </div>
    </div>
  )
}
