// Frontend Types We're Converting to

export interface Integration {
  status: string
  is_active: boolean
  updated_at: string
}

export interface IntegrationMap {
  [key: string]: Integration
}

export interface DashboardResponse {
  isAdmin: boolean
  integrations: IntegrationMap
  email: string
  fullName: string
  availableProviders: string[]
  authUserId: string
  inviteAccepted: boolean
  userId: string
  role: string
  profile_ids: string[]
  additional_emails?: string[]
}

// Backend Types We're Converting FROM that we get from the gql query
interface DashboardNode {
  role?: string
  integrations?: string
  email?: string
  full_name?: string
  available_providers?: string[]
  auth_user_id?: string
  invite_accepted?: boolean
  user_id?: string
  all_profile_ids?: string[]
  additional_emails?: string[]
}

// How we go from backend types to frontend types
export function sanitizeDashboardResponse(
  node: DashboardNode | undefined
): DashboardResponse {
  if (!node) throw new Error("No dashboard data found")
  if (!node.role) throw new Error("Role is required")
  if (!node.integrations) throw new Error("Integrations is required")
  if (!node.email) throw new Error("Email is required")
  if (!node.available_providers)
    throw new Error("Available providers is required")
  if (!node.auth_user_id) throw new Error("Auth user ID is required")
  if (node.invite_accepted === undefined)
    throw new Error("Invite accepted is required")
  if (!node.user_id) throw new Error("User ID is required")

  const integrationsMap: IntegrationMap = JSON.parse(node.integrations)

  return {
    isAdmin: node.role === "ADMIN",
    integrations: integrationsMap,
    email: node.email,
    fullName: node.full_name || "",
    availableProviders: node.available_providers,
    authUserId: node.auth_user_id,
    inviteAccepted: node.invite_accepted,
    userId: node.user_id,
    role: node.role,
    profile_ids: node.all_profile_ids,
    additional_emails: node.additional_emails,
  }
}
