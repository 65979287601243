import {twMerge} from "tailwind-merge"
import {
  IconCircleDashedCheck,
  IconCircleDashedX,
  IconMail,
  IconPhone,
  IconProgressAlert,
  IconProgressCheck,
} from "@tabler/icons-react"

const ChannelIndicator = ({
  text,
  channel_type,
  containerClass,
  size = "md",
}: {
  text?: string
  channel_type: "email" | "slack" | "sms" | "phone" | "other"
  containerClass?: string
  size?: "sm" | "md" | "lg"
}) => {
  const sizeVal = size === "sm" ? 16 : size === "md" ? 20 : 24

  const color = channel_type === "email" ? "blue" : "green"
  const iconColor = channel_type === "email" ? "#3b82f6" : "#00FF00"
  const textColor = channel_type === "email" ? "#3b82f6" : "#00FF00"
  const icon =
    channel_type === "email" ? (
      <IconMail size={sizeVal} color={iconColor} />
    ) : (
      <IconPhone size={sizeVal} color={iconColor} />
    )
  return (
    <div
      className={twMerge(
        `flex w-fit flex-row items-center gap-1 rounded-xl border bg-white p-1 px-2 border-${color}-500`,
        containerClass
      )}
    >
      {/* <div className={`h-2 w-2  rounded-full ${color}`}></div> */}
      {icon}
      {text && (
        <div className="flex flex-row items-center gap-2">
          <p className={twMerge(`font-regular text-xs`, `text-[#3b82f6]`)}>
            {text}
          </p>
        </div>
      )}
    </div>
    // </Tooltip>
  )
}

export default ChannelIndicator
