import {Button} from "@heroui/react"
import {OptionMap, OptionTypes} from "./utils"
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
} from "@heroui/drawer"
import FiltersList from "./FiltersList"
import React from "react"
import SelectedFiltersListView from "./SelectedFiltersListView"

const FiltersDrawerMenu = ({
  isOpen,
  onOpenChange,
  submitFilters,
}: {
  isOpen: boolean
  onOpenChange: (open: boolean) => void
  submitFilters: (filters: OptionMap[]) => void
}) => {
  const [options, setOptions] = React.useState<OptionMap[]>([])

  function addOption(optionType: OptionTypes) {
    if (optionType === "date_range") {
      setOptions((prev) => {
        const newMap = [...prev]
        newMap.push({
          type: "date_range",
          temp_id: crypto.randomUUID(),
          value: {start: "", end: ""},
        })
        return newMap
      })
    }
    if (optionType === "contributors") {
      setOptions((prev) => {
        const newMap = [...prev]
        newMap.push({
          type: "contributors",
          temp_id: crypto.randomUUID(),
          value: {contributors: ""},
        })
        return newMap
      })
    }
  }

  function removeOption(option: OptionMap) {
    setOptions((prev) => {
      return prev.filter((opt) => opt.temp_id !== option.temp_id)
    })
  }

  function changeValue(
    option: OptionMap,
    type: OptionTypes,
    value: OptionMap["value"]
  ) {
    // update the value of the option
    if (type === "contributors") {
      setOptions((prev) => {
        return prev.map((opt) => {
          if (opt.temp_id === option.temp_id) {
            return {...opt, value: value}
          }
          return opt
        })
      })
    }

    if (type === "date_range") {
      console.log("value for new date range", value)
      setOptions((prev) => {
        return prev.map((opt) => {
          if (opt.temp_id === option.temp_id) {
            return {...opt, value: value}
          }
          return opt
        })
      })
    }
  }

  return (
    <Drawer
      isOpen={isOpen}
      onOpenChange={() => {
        onOpenChange(false)
        submitFilters(options)
      }}
    >
      <DrawerContent>
        {(onClose) => (
          <>
            <DrawerHeader className="flex flex-col gap-1">
              Filters and Sorting
            </DrawerHeader>
            <DrawerBody>
              <FiltersList onAdd={addOption} options={options} />
              <SelectedFiltersListView
                options={options}
                removeOption={removeOption}
                changeValue={changeValue}
              />
            </DrawerBody>
            <DrawerFooter>
              <Button color="danger" variant="light" onPress={onClose}>
                Close
              </Button>
              <Button color="primary" onPress={onClose}>
                Add Filters
              </Button>
            </DrawerFooter>
          </>
        )}
      </DrawerContent>
    </Drawer>
  )
}

export default FiltersDrawerMenu
