import {convertDate, convertTimestamp, getDuration} from "../utils"

const DatesPreview = ({
  createdAt,
  lastTimestamp,
}: {
  createdAt: string
  lastTimestamp: string
}) => {
  return (
    <div className="flex flex-row items-start gap-2">
      <p className="w-fit rounded-md border border-black/5 bg-white px-1 py-1 text-xs font-medium ">
        Dates
      </p>

      <div className="ml-2 mt-1 flex flex-col gap-1 text-xs">
        <div className="flex flex-row gap-2">
          <p className="font-medium">Created At:</p>
          <p className="font-regular">{convertDate(createdAt)}</p>
        </div>
        <div className="flex flex-row gap-2">
          <p className="font-medium">Start:</p>
          <p className="font-regular">{convertTimestamp(createdAt)}</p>
        </div>
        <div className="flex flex-row gap-2">
          <p className="font-medium">End:</p>
          <p className="font-regular">{convertTimestamp(lastTimestamp)}</p>
        </div>
        <div className="flex flex-row gap-2">
          <p className="font-medium">Duration:</p>
          <p className="font-regular">
            {getDuration(createdAt, lastTimestamp)}
          </p>
        </div>
      </div>
    </div>
  )
}

export default DatesPreview
