import {useQuery} from "@apollo/client"
import React from "react"
import {
  GetNavigationThreadsDocument,
  GetNavigationThreadsQuery,
} from "@/gql/graphql"
import {Profile, ThreadWithProfiles} from "./shared"

// Utility: Convert a date to a formatted month string.
export const getMonthFromDate = (date: any): string => {
  const dateObj = new Date(date)
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]
  const month = dateObj.getMonth() + 1
  const year = dateObj.getFullYear()
  return `${monthNames[month - 1]} ${year}`
}

// Utility: Group threads by month and sort them.
export const getThreadsByMonthMap = (
  threads: ThreadWithProfiles[],
  sortBy: "created_at" | "last_message_timestamp" = "created_at",
  order: "asc" | "desc" = "desc"
): Map<string, ThreadWithProfiles[]> => {
  const monthThreads = new Map<string, ThreadWithProfiles[]>()
  threads.forEach((thread) => {
    const monthYear = getMonthFromDate(thread.created_at)
    if (!monthThreads.has(monthYear)) {
      monthThreads.set(monthYear, [])
    }
    monthThreads.get(monthYear)?.push(thread)
  })

  // Sort threads within each month.
  monthThreads.forEach((threads) => {
    threads.sort((a, b) => {
      const aValue = new Date(a[sortBy]).getTime()
      const bValue = new Date(b[sortBy]).getTime()
      return order === "asc" ? aValue - bValue : bValue - aValue
    })
  })

  return monthThreads
}

// Transform raw GraphQL data into our desired thread shape.
function sanitizeResponse(
  data: GetNavigationThreadsQuery
): ThreadWithProfiles[] {
  console.debug("🔍 Sanitizing thread data", {rawData: data})

  const threads = data.threadsCollection?.edges.map((edge) => edge.node)
  if (!threads) {
    console.error("❌ Thread data missing")
    throw new Error("No thread data found")
  }

  return threads.map((thread) => {
    console.debug("🧵 Thread:", {threadId: thread.thread_uid})

    const profiles =
      thread?.message_groups?.group_participantsCollection?.edges.map(
        (edge) => edge.node.profiles
      )
    if (!profiles) {
      console.error("❌ Profiles missing:", thread.thread_uid)
      throw new Error(`No profiles data found on thread ${thread.thread_uid}`)
    }

    return {
      thread_uid: thread.thread_uid,
      created_at: thread.created_at,
      title: thread.title,
      description: thread.description,
      last_message_timestamp: thread.last_message_timestamp,
      profiles: profiles as Profile[],
    }
  })
}

// Final hook.
export function useGetNavigationThreads() {
  const result = useQuery<GetNavigationThreadsQuery>(
    GetNavigationThreadsDocument,
    {
      variables: {
        orderBy: [
          {
            last_message_timestamp: "DescNullsLast",
          },
        ],
      },
      onError: (error) => {
        console.error("❌ Query failed:", error.message)
      },
      onCompleted: (data) => {
        console.info("✅ Query completed:", {
          hasData: !!data,
          threadCount: data?.threadsCollection?.edges?.length ?? 0,
        })
      },
    }
  )

  // Combine sanitization and month organization into a single memoized operation
  const {sanitizedData, monthThreads} = React.useMemo(() => {
    console.debug("🔄 Processing data:", {
      hasData: !!result.data,
    })

    if (!result.data) {
      return {
        sanitizedData: undefined,
        monthThreads: new Map<string, ThreadWithProfiles[]>(),
      }
    }

    const processed = sanitizeResponse(result.data)
    console.debug("📅 Organizing by month:", {
      threadCount: processed?.length ?? 0,
    })

    return {
      sanitizedData: processed,
      monthThreads: getThreadsByMonthMap(processed),
    }
  }, [result.data])

  return {
    ...result,
    data: sanitizedData,
    monthThreads,
  }
}
