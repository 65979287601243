import {DateInput, DatePicker, Input, Select, SelectItem} from "@heroui/react"
import {IconX, IconMinus} from "@tabler/icons-react"
import {ContributorFilterOption, OptionMap, OptionTypes} from "./utils"
import {IconWrapper} from "./utils"
import {FilterColors, FilterIcons} from "./utils"
import {useGetOrgUsersDashboard} from "@/utils/graphQL/queries/userDashboard/useGetOrgUsersDashboard"
import {MessageParticipantType} from "@/utils/graphQL/queries/threads/shared"
import React from "react"

const DateRangeFilter = ({
  option,
  removeOption,
  changeValue,
}: {
  option: OptionMap
  removeOption: (option: OptionMap) => void
  changeValue: (
    option: OptionMap,
    type: OptionTypes,
    value: OptionMap["value"]
  ) => void
}) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center justify-between text-small">
        <div className="flex items-center gap-2">
          <IconWrapper className={FilterColors.date_range}>
            {FilterIcons.date_range}
          </IconWrapper>
          <p>Date Range Filter</p>
        </div>
        <IconX size={16} onClick={() => removeOption(option)} />
      </div>

      <DateInput
        label="Start Point"
        value={option.value.start !== "" ? option.value.start : undefined}
        onChange={(e) => {
          if (e?.toString() !== "") {
            changeValue(option, "date_range", {start: e, end: option.value.end})
          }
          // const month = e.month
          // const day = e.day
          // const year = e.year
          // const date = new Date(year, month, day)
          // if (e.year >= 1900) {
          //   changeValue(option, "date_range", {start: e.toString()})
          // }
        }}
      />
      <DateInput
        label="End Point"
        value={option.value.end !== "" ? option.value.end : undefined}
        // focusable={false}
        onChange={(e) => {
          // console.log("e", e)
          console.log("e", e?.toString())
          if (e?.toString() !== "") {
            changeValue(option, "date_range", {
              start: option.value.start,
              end: e,
            })
          }
          // const date = new Date(year, month, day)
        }}
        onSubmitCapture={() => {
          console.log("submit")
        }}
      />
    </div>
  )
}

const ContributorsFilter = ({
  option,
  removeOption,
  changeValue,
}: {
  option: OptionMap
  removeOption: (option: OptionMap) => void
  changeValue: (
    option: OptionMap,
    type: "contributors",
    value: ContributorFilterOption
  ) => void
}) => {
  const {data: users, loading: usersLoading} = useGetOrgUsersDashboard()
  const [inputValue, setInputValue] = React.useState("")
  const [isSearching, setIsSearching] = React.useState(false)

  if (usersLoading) {
    return <div>Loading...</div>
  }

  const filteredUsers = users?.filter(
    (user) =>
      user?.fullName?.toLowerCase().includes(inputValue?.toLowerCase() ?? "") ??
      false
  )

  const selectedUser = option.value.contributors?.[0]

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center justify-between text-small">
        <div className="flex items-center gap-2">
          <IconWrapper className={FilterColors.contributors}>
            {FilterIcons.contributors}
          </IconWrapper>
          <p>Contributors Filter</p>
        </div>
        <IconX
          size={16}
          onClick={() => removeOption(option)}
          className="pr-1"
        />
      </div>

      <div className="relative">
        {selectedUser && !isSearching ? (
          <div className="flex items-center gap-2 rounded-md border border-gray-200 p-2">
            <div className="flex flex-1 items-center gap-2">
              <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100">
                {selectedUser.full_name[0]}
              </div>
              <div className="flex flex-col">
                <span>{selectedUser.full_name}</span>
                <span className="text-xs text-gray-500">
                  {selectedUser.emails.join(", ")}
                </span>
              </div>
            </div>
            <div className="flex h-3.5 w-3.5 items-center justify-center rounded-full border border-red-300 hover:bg-red-50">
              <IconMinus
                size={8}
                className="cursor-pointer text-red-300"
                onClick={() => {
                  changeValue(option, "contributors", {contributors: []})
                  setInputValue("")
                }}
              />
            </div>
          </div>
        ) : (
          <>
            <Input
              label="Contributors"
              placeholder="Search contributors"
              value={inputValue}
              onChange={(e) => {
                setInputValue(e.target.value)
                setIsSearching(true)
              }}
              onFocus={() => setIsSearching(true)}
            />
            {isSearching && (
              <div className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white shadow-lg">
                {filteredUsers && filteredUsers.length > 0 ? (
                  filteredUsers.map((user) => (
                    <div
                      key={user.authUserId}
                      className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                      onClick={() => {
                        setInputValue("")
                        setIsSearching(false)
                        changeValue(option, "contributors", {
                          contributors: [
                            {
                              auth_user_id: user.authUserId,
                              full_name: user.fullName,
                              profile_ids: user.profile_ids,
                              emails: [
                                user.email,
                                ...(user.additional_emails ?? []),
                              ],
                              phone_numbers: [],
                            },
                          ],
                        })
                      }}
                    >
                      {user.fullName}
                    </div>
                  ))
                ) : (
                  <div className="px-4 py-2 text-gray-500">No users found</div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

const SelectedFilterItemView = ({
  optionKey,
  option,
  removeOption,
  changeValue,
}: {
  optionKey: OptionTypes
  option: OptionMap
  removeOption: (option: OptionMap) => void
  changeValue: (
    option: OptionMap,
    type: OptionTypes,
    value: OptionMap["value"]
  ) => void
}) => {
  const contentWrapper = (content: React.ReactNode) => (
    <div
    // className="flex flex-col gap-0 divide-default-300/50 overflow-visible rounded-medium bg-content1 p-0 px-2 py-2 shadow-small dark:divide-default-100/80"
    // inert={false}
    >
      {content}
    </div>
  )

  switch (optionKey) {
    case "date_range":
      return contentWrapper(
        <DateRangeFilter
          option={option}
          removeOption={removeOption}
          changeValue={changeValue}
        />
      )
    case "contributors":
      return contentWrapper(
        <ContributorsFilter
          option={option}
          removeOption={removeOption}
          changeValue={changeValue}
        />
      )
    default:
      return null
  }
}

export default SelectedFilterItemView
